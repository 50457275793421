import {
  LoginRequest, RegisterRequest, VerifyRequest,
} from '../proto/operator/registration_pb';
import { createServiceClient } from './createServiceClient';
import { RegistrationService } from '../proto/operator/registration_pb_service';
import { mapToProtoAddress } from './parcelAdapter';
import { Empty } from '../proto/models/general_pb';

const client = createServiceClient(RegistrationService);

export const register = async (
  tenantName,
  tenantAcronym,
  depotName,
  depotAddress,
  maintainerName,
  maintainerEmail,
  maintainerPassword,
) => {
  const request = new RegisterRequest();
  request.setTenantname(tenantName);
  request.setTenantacronym(tenantAcronym);
  const address = mapToProtoAddress(depotAddress);
  request.setDepotname(depotName);
  request.setDepotaddress(address);
  request.setMaintainername(maintainerName);
  request.setMaintaineremail(maintainerEmail);
  request.setMaintainerpassword(maintainerPassword);
  const response = await client.register(request);
  return response.toObject();
};

export const verify = async (verificationCode) => {
  const request = new VerifyRequest();
  request.setVerificationcode(verificationCode);
  const response = await client.verify(request);
  return response.toObject();
};

export const login = async (tenant, email, password) => {
  const request = new LoginRequest();
  request.setTenantacronym(tenant);
  request.setEmail(email);
  request.setPassword(password);
  const response = await client.login(request);
  return response.toObject();
};

export const logout = async () => {
  const response = await client.logout(new Empty());
  return response.toObject();
};

export const unregister = async () => {
  const response = await client.unregister(new Empty());
  return response.toObject();
};
