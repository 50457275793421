import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useAsync } from 'react-async';
import DriverTable from '../components/DriverTable';
import { listDrivers } from '../api/driverAdapter';
import { useSideDrawer } from '../components/SideDrawer';
import DriverForm from '../components/DriverForm';
import { useError } from '../components/ErrorContext';

function AddRiderButton({ onClick }) {
  return (
    <Button
      variant="contained"
      startIcon={<PersonAddIcon />}
      onClick={onClick}
    >
      Person hinzufügen
    </Button>
  );
}

AddRiderButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default function DriverPage() {
  const { openSideDrawer } = useSideDrawer();
  const { showGenericError } = useError();

  const [drivers, setDrivers] = useState([]);
  useAsync(listDrivers, {
    onResolve: (riders) => setDrivers(riders),
    onReject: showGenericError,
  });

  const onUpdate = (updatedDrivers) => {
    setDrivers(updatedDrivers);
  };

  return (
    <>
      <section className="section--padding-top">
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="h3" className="no-margin">Personal</Typography>
          <AddRiderButton onClick={() => openSideDrawer(<DriverForm mode="create" onUpdate={onUpdate} />)} />
        </Stack>
      </section>
      <section className="section--padding-top">
        <DriverTable
          drivers={drivers}
          onRowClick={(driver) => openSideDrawer(<DriverForm mode="update" driver={driver} onUpdate={onUpdate} />)}
        />
      </section>
    </>
  );
}
