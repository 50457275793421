import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import React from 'react';
import PropTypes from 'prop-types';

export default function SaveAbortButtons({ onSave, onAbort, disabled }) {
  return (
    <Stack direction="row" spacing={2} pt={2}>
      <Button
        variant="contained"
        size="large"
        onClick={onSave}
        disabled={disabled}
      >
        Speichern
      </Button>
      <Button
        variant="outlined"
        size="large"
        onClick={onAbort}
      >
        Abbrechen
      </Button>
    </Stack>
  );
}

SaveAbortButtons.defaultProps = {
  disabled: false,
};

SaveAbortButtons.propTypes = {
  onSave: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
