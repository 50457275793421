import React from 'react';
import { render } from 'react-dom';
import {
  Routes,
  Route,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import { history } from './utils/history';
import './index.css';
import IndexPage from './pages/IndexPage';
import DriverPage from './pages/DriverPage';
import ParcelPage from './pages/ParcelPage';
import VehiclePage from './pages/VehiclePage';
import SettingsPage from './pages/SettingsPage';
import NotFoundPage from './pages/NotFoundPage';
import config from './config';
import BaseLayout from './layout/BaseLayout';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import VerifyPage from './pages/VerifyPage';
import ProtectedRoute from './components/ProtectedRoute';
import ParcelUploadPage from './pages/ParcelUploadPage';
import initI18n from './i18n';
import StatisticsPage from './pages/StatisticsPage';

initI18n();

render(
  <React.StrictMode>
    <HistoryRouter basename={config.BASENAME} history={history}>
      <BaseLayout>
        <Routes>
          <Route path="/" element={<ProtectedRoute><IndexPage /></ProtectedRoute>} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/verify" element={<VerifyPage />} />
          <Route path="/parcels" element={<ProtectedRoute><ParcelPage /></ProtectedRoute>} />
          <Route path="/parcels-import" element={<ProtectedRoute><ParcelUploadPage /></ProtectedRoute>} />
          <Route path="/drivers" element={<ProtectedRoute><DriverPage /></ProtectedRoute>} />
          <Route path="/vehicles" element={<ProtectedRoute><VehiclePage /></ProtectedRoute>} />
          <Route path="/statistics" element={<ProtectedRoute><StatisticsPage /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BaseLayout>
    </HistoryRouter>
  </React.StrictMode>,
  document.getElementById('root'), // eslint-disable-line no-undef
);
