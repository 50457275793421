import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAsync } from 'react-async';
import VehicleTable from '../components/VehicleTable';
import { useSideDrawer } from '../components/SideDrawer';
import VehicleForm from '../components/VehicleForm';
import { listVehicles } from '../api/vehicleAdapter';
import { useError } from '../components/ErrorContext';

function AddVehicleButton({ onClick }) {
  return (
    <Button
      variant="contained"
      startIcon={<AddCircleIcon />}
      onClick={onClick}
    >
      Fahrzeug hinzufügen
    </Button>
  );
}

AddVehicleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default function VehiclePage() {
  const { openSideDrawer } = useSideDrawer();
  const { showGenericError } = useError();

  const [vehicles, setVehicles] = useState([]);
  useAsync(listVehicles, {
    onResolve: (updatedVehicles) => setVehicles(updatedVehicles),
    onReject: showGenericError,
  });

  const onUpdate = (updatedVehicles) => {
    setVehicles(updatedVehicles);
  };

  return (
    <>
      <section className="section--padding-top">
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="h3" className="no-margin">Fahrzeuge</Typography>
          <AddVehicleButton onClick={() => openSideDrawer(<VehicleForm mode="create" onUpdate={onUpdate} />)} />
        </Stack>
      </section>
      <section className="section--padding-top">
        <VehicleTable
          vehicles={vehicles}
          onRowClick={(vehicle) => openSideDrawer(<VehicleForm mode="update" vehicle={vehicle} onUpdate={onUpdate} />)}
        />
      </section>
    </>
  );
}
