import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { addressComplete, radiusAddressComplete } from '../api/utilAdapter';
import { addressToString } from '../api/parcelAdapter';

export default function AddressForm({
  address, onUpdate, disableDepotRadius, hideName,
}) {
  const [labelList, setLabelList] = useState([]);
  const [inputText, setInputText] = useState('');
  const [inputTextOld, setInputTextOld] = useState(null);
  const [intervalToggle, setIntervalToggle] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const request = disableDepotRadius ? addressComplete : radiusAddressComplete;
      const response = await request(inputTextOld);
      setLabelList(response);
    };
    fetchData();
  }, [inputTextOld, setLabelList, disableDepotRadius]);

  const onChange = (changes) => {
    onUpdate({ ...address, ...changes });
  };

  const onInternalChange = (e, option) => {
    const optionWithoutName = option;
    delete optionWithoutName.name;
    onChange(optionWithoutName);
  };

  useEffect(() => {
    if (inputTextOld !== inputText) {
      setInputTextOld(inputText);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalToggle]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalToggle((toggle) => !toggle);
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <Stack direction="column" alignItems="stretch" spacing={3} sx={{ mb: 1 }}>
      {!hideName && (
      <TextField
        label="Name"
        value={address.name}
        fullWidth
        onChange={(e) => onChange({ name: e.target.value })}
      />
      )}
      <Autocomplete
        options={labelList}
        filterOptions={(x) => x}
        autoHighlight
        fullWidth
        inputValue={inputText}
        noOptionsText="Kein Treffer!"
        onChange={onInternalChange}
        onInputChange={(event, newInputValueMapBox) => setInputText(newInputValueMapBox)}
        defaultValue={address}
        getOptionLabel={(option) => addressToString(option)}
        renderInput={(params) => <TextField {...params} label="Adresse" />}
        freeSolo
        disableClearable
      />
    </Stack>
  );
}

export const addressPropType = PropTypes.shape({
  name: PropTypes.string,
  street: PropTypes.string,
  houseNumber: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
});

AddressForm.defaultProps = {
  address: {
    name: '',
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    country: '',
    latitude: null,
    longitude: null,
  },
  onUpdate: () => {},
  disableDepotRadius: true,
  hideName: false,
};

AddressForm.propTypes = {
  address: addressPropType,
  onUpdate: PropTypes.func,
  disableDepotRadius: PropTypes.bool,
  hideName: PropTypes.bool,
};
