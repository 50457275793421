import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { t } from 'i18next';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import Tooltip from '@mui/material/Tooltip';
import { parcelPropType } from './ParcelForm';
import { Parcel } from '../proto/models/parcel_pb';
import { enumToDataGridValueOptions } from './EnumMenu';
import { addressToString } from '../api/parcelAdapter';
import { hashToHslColor } from '../utils/color';
import { inlineTimeWindow } from './TimeWindowForm';

export function ParcelTypeIcon({ parcel, fontSize }) {
  const hash = parcel.type === 'DELIVERY' ? parcel.id : parcel.companionId;
  const randomColor = hashToHslColor(hash, '35%');
  const color = parcel.companionId ? randomColor : 'black';
  return (
    <Tooltip title={t(`parcel.type.${parcel.type}`)}>
      {(parcel.type === 'DELIVERY')
        ? <UnarchiveIcon style={{ fill: color }} fontSize={fontSize} />
        : <ArchiveIcon style={{ fill: color }} fontSize={fontSize} />}
    </Tooltip>
  );
}

ParcelTypeIcon.defaultProps = {
  fontSize: undefined,
};

ParcelTypeIcon.propTypes = {
  parcel: parcelPropType.isRequired,
  fontSize: PropTypes.string,
};

const columns = [
  {
    field: 'type',
    headerName: 'Typ',
    type: 'singleSelect',
    flex: 0.5,
    valueOptions: enumToDataGridValueOptions(Parcel.Type),
    valueFormatter: ({ value }) => (t(`parcel.type.${value}`)),
    renderCell: ({ row }) => (
      <ParcelTypeIcon parcel={row} />
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    valueGetter: ({ row }) => row.address?.name,
  },
  {
    field: 'address',
    headerName: 'Adresse',
    flex: 2,
    valueFormatter: ({ value }) => addressToString(value),
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: enumToDataGridValueOptions(Parcel.Status),
    valueFormatter: ({ value }) => (t(`parcel.status.${value}`)),
  },
  {
    field: 'timeWindow',
    headerName: 'Zeitfenster',
    flex: 1,
    valueGetter: inlineTimeWindow,
  },
  {
    field: 'customerNumber',
    headerName: 'Kundennummer',
  },
  {
    field: 'barcode',
    headerName: 'Barcode',
  },
  {
    field: 'notes',
    headerName: 'Notizen',
  },
];

export default function ParcelTable({ parcels, onRowClick }) {
  return (
    <DataGrid
      style={{ height: '68vh', minHeight: 400 }}
      rows={parcels}
      columns={columns}
      onRowClick={(e) => onRowClick(e.row)}
    />
  );
}

ParcelTable.defaultProps = {
  onRowClick: () => {},
};

ParcelTable.propTypes = {
  parcels: PropTypes.arrayOf(parcelPropType).isRequired,
  onRowClick: PropTypes.func,
};
