import { createChannel, createClientFactory } from 'nice-grpc-web';
import { authMiddleware } from './middleware';
import config from '../config';

const channel = createChannel(config.API_URL);
/**
 * Create client with middleware from service definition
 * @param serviceDefinition
 * @returns {serviceDefinition}
 */
export const createServiceClient = (serviceDefinition) => createClientFactory()
  .use(authMiddleware)
  .create(serviceDefinition, channel);
