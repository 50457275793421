import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import config from '../config';

const firebaseConfig = {
  apiKey: 'AIzaSyD_Y0_eSuKNJW39lHvQBOX9w50G5s2-wcM',
  authDomain: 'gridos.firebaseapp.com',
  projectId: 'gridos',
  storageBucket: 'gridos.appspot.com',
  messagingSenderId: '320639462274',
  appId: config.FIREBASE_ANALYTICS_APPID,
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

export default function logAnalyticsEvent(...args) {
  logEvent(analytics, ...args);
}
