// source: models/general.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.gridos.proto.models.Activity', null, global);
goog.exportSymbol('proto.gridos.proto.models.Activity.Event', null, global);
goog.exportSymbol('proto.gridos.proto.models.Activity.PropertyChange', null, global);
goog.exportSymbol('proto.gridos.proto.models.Activity.TypeCase', null, global);
goog.exportSymbol('proto.gridos.proto.models.Address', null, global);
goog.exportSymbol('proto.gridos.proto.models.Depot', null, global);
goog.exportSymbol('proto.gridos.proto.models.Empty', null, global);
goog.exportSymbol('proto.gridos.proto.models.Location', null, global);
goog.exportSymbol('proto.gridos.proto.models.Tenant', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.models.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.models.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.models.Empty.displayName = 'proto.gridos.proto.models.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.models.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.models.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.models.Address.displayName = 'proto.gridos.proto.models.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.models.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.models.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.models.Location.displayName = 'proto.gridos.proto.models.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.models.Tenant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.models.Tenant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.models.Tenant.displayName = 'proto.gridos.proto.models.Tenant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.models.Depot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.models.Depot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.models.Depot.displayName = 'proto.gridos.proto.models.Depot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.models.Activity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.gridos.proto.models.Activity.oneofGroups_);
};
goog.inherits(proto.gridos.proto.models.Activity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.models.Activity.displayName = 'proto.gridos.proto.models.Activity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.models.Activity.PropertyChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.models.Activity.PropertyChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.models.Activity.PropertyChange.displayName = 'proto.gridos.proto.models.Activity.PropertyChange';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.models.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.models.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.models.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.models.Empty}
 */
proto.gridos.proto.models.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.models.Empty;
  return proto.gridos.proto.models.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.models.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.models.Empty}
 */
proto.gridos.proto.models.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.models.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.models.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.models.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.models.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.models.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.models.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    street: jspb.Message.getFieldWithDefault(msg, 2, ""),
    housenumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    zipcode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, ""),
    location: (f = msg.getLocation()) && proto.gridos.proto.models.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.models.Address}
 */
proto.gridos.proto.models.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.models.Address;
  return proto.gridos.proto.models.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.models.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.models.Address}
 */
proto.gridos.proto.models.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHousenumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipcode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 7:
      var value = new proto.gridos.proto.models.Location;
      reader.readMessage(value,proto.gridos.proto.models.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.models.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.models.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.models.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHousenumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getZipcode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.gridos.proto.models.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.gridos.proto.models.Address.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Address} returns this
 */
proto.gridos.proto.models.Address.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string street = 2;
 * @return {string}
 */
proto.gridos.proto.models.Address.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Address} returns this
 */
proto.gridos.proto.models.Address.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string houseNumber = 3;
 * @return {string}
 */
proto.gridos.proto.models.Address.prototype.getHousenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Address} returns this
 */
proto.gridos.proto.models.Address.prototype.setHousenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string zipCode = 4;
 * @return {string}
 */
proto.gridos.proto.models.Address.prototype.getZipcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Address} returns this
 */
proto.gridos.proto.models.Address.prototype.setZipcode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.gridos.proto.models.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Address} returns this
 */
proto.gridos.proto.models.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.gridos.proto.models.Address.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Address} returns this
 */
proto.gridos.proto.models.Address.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Location location = 7;
 * @return {?proto.gridos.proto.models.Location}
 */
proto.gridos.proto.models.Address.prototype.getLocation = function() {
  return /** @type{?proto.gridos.proto.models.Location} */ (
    jspb.Message.getWrapperField(this, proto.gridos.proto.models.Location, 7));
};


/**
 * @param {?proto.gridos.proto.models.Location|undefined} value
 * @return {!proto.gridos.proto.models.Address} returns this
*/
proto.gridos.proto.models.Address.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gridos.proto.models.Address} returns this
 */
proto.gridos.proto.models.Address.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gridos.proto.models.Address.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.models.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.models.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.models.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    elevation: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tag: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.models.Location}
 */
proto.gridos.proto.models.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.models.Location;
  return proto.gridos.proto.models.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.models.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.models.Location}
 */
proto.gridos.proto.models.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setElevation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.models.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.models.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.models.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getElevation();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.gridos.proto.models.Location.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gridos.proto.models.Location} returns this
 */
proto.gridos.proto.models.Location.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.gridos.proto.models.Location.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gridos.proto.models.Location} returns this
 */
proto.gridos.proto.models.Location.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 elevation = 3;
 * @return {number}
 */
proto.gridos.proto.models.Location.prototype.getElevation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.gridos.proto.models.Location} returns this
 */
proto.gridos.proto.models.Location.prototype.setElevation = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string tag = 4;
 * @return {string}
 */
proto.gridos.proto.models.Location.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Location} returns this
 */
proto.gridos.proto.models.Location.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.models.Tenant.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.models.Tenant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.models.Tenant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Tenant.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    acronym: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.models.Tenant}
 */
proto.gridos.proto.models.Tenant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.models.Tenant;
  return proto.gridos.proto.models.Tenant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.models.Tenant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.models.Tenant}
 */
proto.gridos.proto.models.Tenant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAcronym(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.models.Tenant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.models.Tenant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.models.Tenant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Tenant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAcronym();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.gridos.proto.models.Tenant.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Tenant} returns this
 */
proto.gridos.proto.models.Tenant.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.gridos.proto.models.Tenant.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Tenant} returns this
 */
proto.gridos.proto.models.Tenant.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string acronym = 3;
 * @return {string}
 */
proto.gridos.proto.models.Tenant.prototype.getAcronym = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Tenant} returns this
 */
proto.gridos.proto.models.Tenant.prototype.setAcronym = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.models.Depot.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.models.Depot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.models.Depot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Depot.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && proto.gridos.proto.models.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.models.Depot}
 */
proto.gridos.proto.models.Depot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.models.Depot;
  return proto.gridos.proto.models.Depot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.models.Depot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.models.Depot}
 */
proto.gridos.proto.models.Depot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.gridos.proto.models.Address;
      reader.readMessage(value,proto.gridos.proto.models.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.models.Depot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.models.Depot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.models.Depot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Depot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.gridos.proto.models.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.gridos.proto.models.Depot.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Depot} returns this
 */
proto.gridos.proto.models.Depot.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.gridos.proto.models.Depot.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Depot} returns this
 */
proto.gridos.proto.models.Depot.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Address address = 3;
 * @return {?proto.gridos.proto.models.Address}
 */
proto.gridos.proto.models.Depot.prototype.getAddress = function() {
  return /** @type{?proto.gridos.proto.models.Address} */ (
    jspb.Message.getWrapperField(this, proto.gridos.proto.models.Address, 3));
};


/**
 * @param {?proto.gridos.proto.models.Address|undefined} value
 * @return {!proto.gridos.proto.models.Depot} returns this
*/
proto.gridos.proto.models.Depot.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gridos.proto.models.Depot} returns this
 */
proto.gridos.proto.models.Depot.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gridos.proto.models.Depot.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.gridos.proto.models.Activity.oneofGroups_ = [[6,7]];

/**
 * @enum {number}
 */
proto.gridos.proto.models.Activity.TypeCase = {
  TYPE_NOT_SET: 0,
  STATUS: 6,
  PROPERTY: 7
};

/**
 * @return {proto.gridos.proto.models.Activity.TypeCase}
 */
proto.gridos.proto.models.Activity.prototype.getTypeCase = function() {
  return /** @type {proto.gridos.proto.models.Activity.TypeCase} */(jspb.Message.computeOneofCase(this, proto.gridos.proto.models.Activity.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.models.Activity.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.models.Activity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.models.Activity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Activity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authorid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    referenceid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    referencetype: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    property: (f = msg.getProperty()) && proto.gridos.proto.models.Activity.PropertyChange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.models.Activity}
 */
proto.gridos.proto.models.Activity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.models.Activity;
  return proto.gridos.proto.models.Activity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.models.Activity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.models.Activity}
 */
proto.gridos.proto.models.Activity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferencetype(value);
      break;
    case 6:
      var value = /** @type {!proto.gridos.proto.models.Activity.Event} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = new proto.gridos.proto.models.Activity.PropertyChange;
      reader.readMessage(value,proto.gridos.proto.models.Activity.PropertyChange.deserializeBinaryFromReader);
      msg.setProperty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.models.Activity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.models.Activity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.models.Activity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Activity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthorid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReferenceid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReferencetype();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {!proto.gridos.proto.models.Activity.Event} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getProperty();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.gridos.proto.models.Activity.PropertyChange.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.gridos.proto.models.Activity.Event = {
  CREATED: 0,
  DELETED: 1,
  LOGGED_IN: 2,
  LOGGED_OUT: 3,
  ASSIGNED: 4,
  REASSIGNED: 5,
  UNASSIGNED: 6,
  APPROVED: 7,
  COMPLETED: 8,
  CANCELLED: 9,
  ARCHIVED: 10
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.models.Activity.PropertyChange.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.models.Activity.PropertyChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.models.Activity.PropertyChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Activity.PropertyChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertytype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertyname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromvalue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tovalue: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.models.Activity.PropertyChange}
 */
proto.gridos.proto.models.Activity.PropertyChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.models.Activity.PropertyChange;
  return proto.gridos.proto.models.Activity.PropertyChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.models.Activity.PropertyChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.models.Activity.PropertyChange}
 */
proto.gridos.proto.models.Activity.PropertyChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertytype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromvalue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTovalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.models.Activity.PropertyChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.models.Activity.PropertyChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.models.Activity.PropertyChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.models.Activity.PropertyChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertytype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertyname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromvalue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTovalue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string propertyType = 1;
 * @return {string}
 */
proto.gridos.proto.models.Activity.PropertyChange.prototype.getPropertytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Activity.PropertyChange} returns this
 */
proto.gridos.proto.models.Activity.PropertyChange.prototype.setPropertytype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string propertyName = 2;
 * @return {string}
 */
proto.gridos.proto.models.Activity.PropertyChange.prototype.getPropertyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Activity.PropertyChange} returns this
 */
proto.gridos.proto.models.Activity.PropertyChange.prototype.setPropertyname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fromValue = 3;
 * @return {string}
 */
proto.gridos.proto.models.Activity.PropertyChange.prototype.getFromvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Activity.PropertyChange} returns this
 */
proto.gridos.proto.models.Activity.PropertyChange.prototype.setFromvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string toValue = 4;
 * @return {string}
 */
proto.gridos.proto.models.Activity.PropertyChange.prototype.getTovalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Activity.PropertyChange} returns this
 */
proto.gridos.proto.models.Activity.PropertyChange.prototype.setTovalue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.gridos.proto.models.Activity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Activity} returns this
 */
proto.gridos.proto.models.Activity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string timestamp = 2;
 * @return {string}
 */
proto.gridos.proto.models.Activity.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Activity} returns this
 */
proto.gridos.proto.models.Activity.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string authorID = 3;
 * @return {string}
 */
proto.gridos.proto.models.Activity.prototype.getAuthorid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Activity} returns this
 */
proto.gridos.proto.models.Activity.prototype.setAuthorid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string referenceID = 4;
 * @return {string}
 */
proto.gridos.proto.models.Activity.prototype.getReferenceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Activity} returns this
 */
proto.gridos.proto.models.Activity.prototype.setReferenceid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string referenceType = 5;
 * @return {string}
 */
proto.gridos.proto.models.Activity.prototype.getReferencetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.models.Activity} returns this
 */
proto.gridos.proto.models.Activity.prototype.setReferencetype = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Event status = 6;
 * @return {!proto.gridos.proto.models.Activity.Event}
 */
proto.gridos.proto.models.Activity.prototype.getStatus = function() {
  return /** @type {!proto.gridos.proto.models.Activity.Event} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.gridos.proto.models.Activity.Event} value
 * @return {!proto.gridos.proto.models.Activity} returns this
 */
proto.gridos.proto.models.Activity.prototype.setStatus = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.gridos.proto.models.Activity.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.gridos.proto.models.Activity} returns this
 */
proto.gridos.proto.models.Activity.prototype.clearStatus = function() {
  return jspb.Message.setOneofField(this, 6, proto.gridos.proto.models.Activity.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gridos.proto.models.Activity.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PropertyChange property = 7;
 * @return {?proto.gridos.proto.models.Activity.PropertyChange}
 */
proto.gridos.proto.models.Activity.prototype.getProperty = function() {
  return /** @type{?proto.gridos.proto.models.Activity.PropertyChange} */ (
    jspb.Message.getWrapperField(this, proto.gridos.proto.models.Activity.PropertyChange, 7));
};


/**
 * @param {?proto.gridos.proto.models.Activity.PropertyChange|undefined} value
 * @return {!proto.gridos.proto.models.Activity} returns this
*/
proto.gridos.proto.models.Activity.prototype.setProperty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.gridos.proto.models.Activity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gridos.proto.models.Activity} returns this
 */
proto.gridos.proto.models.Activity.prototype.clearProperty = function() {
  return this.setProperty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gridos.proto.models.Activity.prototype.hasProperty = function() {
  return jspb.Message.getField(this, 7) != null;
};


goog.object.extend(exports, proto.gridos.proto.models);
