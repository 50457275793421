import React from 'react';
import { Button, Typography, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { unregister } from '../api/loginAdapter';
import { useAuth } from '../components/AuthContext';
import { useError } from '../components/ErrorContext';
import config from '../config';

export default function SettingsPage() {
  const { showGenericError } = useError();
  const { logout } = useAuth();
  const onDelete = async () => {
    try {
      await unregister();
      await logout();
    } catch (e) {
      showGenericError(e);
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleConfirm = (e) => {
    handleClose(e);
    onDelete();
  };
  return (
    <>
      <section>
        <Typography variant="h3">Einstellungen</Typography>
      </section>
      <section>
        Version:&nbsp;
        { `${config.APP_VERSION_NAME}:${config.APP_VERSION_CODE} (${config.APP_VERSION_HASH})` }
      </section>
      <section>
        <h4>Account löschen</h4>
        <Stack direction="row">
          Um Ihren Account und alle zugehörigen Daten zu löschen, klicken Sie bitte&nbsp;
          <div
            style={{
              color: 'blue',
              textDecoration: 'underline',
              textDecorationColor: 'blue',
              cursor: 'pointer',
            }}
            role="button"
            onClick={(e) => handleOpen(e)}
            onKeyDown={() => handleOpen(true)}
            tabIndex={0}
          >
            hier
          </div>
          .
        </Stack>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Account löschen
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sind Sie sicher, dass dieser Account gelöscht werden soll?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" size="large" onClick={handleConfirm} autoFocus color="error">Account löschen</Button>
            <Button variant="outlined" size="large" onClick={handleClose} color="error">Abbrechen</Button>
          </DialogActions>
        </Dialog>
      </section>
    </>
  );
}
