import { Metadata, ClientError } from 'nice-grpc-web';
import { provideCurrentToken } from './helpers';
import { history } from '../utils/history';
import config from '../config';

export async function* authMiddleware(call, options) {
  const token = provideCurrentToken();
  const metadata = Metadata(options.metadata);
  metadata.set('Authorization', `Bearer ${token}`);
  metadata.set('api_version', config.API_VERSION);

  try {
    return yield* call.next(call.request, { ...options, metadata });
  } catch (error) {
    if ((error instanceof ClientError) && (error.code === 16)) {
      // If unauthenticated error, redirect to login
      history.push(`${config.BASENAME_NO_TRAILING}/login`, { from: history.location });
    }
    throw error;
  }
}
