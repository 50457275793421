import React from 'react';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import PropTypes from 'prop-types';
import { timeStringOf } from '../utils/datetime';

export const timeWindowToString = (timeWindow) => {
  if (!timeWindow) return '';
  const start = timeStringOf(timeWindow.start);
  const end = timeStringOf(timeWindow.end);
  return `${start} — ${end} Uhr`;
};

export const inlineTimeWindow = ({ row: parcel }) => {
  if (!parcel || !parcel.timeWindow) {
    return '';
  }
  return timeWindowToString(parcel.timeWindow);
};

export default function TimeWindowForm({ timeWindow, onUpdate }) {
  const onChange = (changes) => {
    onUpdate({ ...timeWindow, ...changes });
  };

  const timestampToMoment = (timestamp) => (timestamp ? moment(timestamp) : null);

  const fromTimePickerEvent = (e) => (e ? e.format('YYYY-MM-DDTHH:mmZ') : null);

  return (
    <Grid container spacing={3} sx={{ mt: 0, mb: 1 }}>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            label="Lieferfenster Beginn"
            value={timestampToMoment(timeWindow.start)}
            maxTime={timestampToMoment(timeWindow.end)}
            fullWidth
            ampm={false}
            clearable
            onChange={(e) => onChange({ start: fromTimePickerEvent(e) })}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            label="Lieferfenster Ende"
            value={timestampToMoment(timeWindow.end)}
            minTime={timestampToMoment(timeWindow.start)}
            fullWidth
            ampm={false}
            onChange={(e) => onChange({ end: fromTimePickerEvent(e) })}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

export const timeWindowPropType = PropTypes.shape({
  start: PropTypes.string,
  end: PropTypes.string,
});

TimeWindowForm.defaultProps = {
  timeWindow: {
    start: '',
    end: '',
  },
  onUpdate: () => {},
};

TimeWindowForm.propTypes = {
  timeWindow: timeWindowPropType,
  onUpdate: PropTypes.func,
};
