import React, { useEffect, useState } from 'react';
import {
  Backdrop, CircularProgress, Paper,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import background from '../images/background.jpg';
import { useError } from '../components/ErrorContext';
import Logo from '../images/strazoon-light.svg';
import { useAuth } from '../components/AuthContext';

export default function VerifyPage() {
  const [searchParams] = useSearchParams();
  const [token] = useState(searchParams.get('token'));
  const { showGenericError } = useError();
  const { verify } = useAuth();

  useEffect(() => {
    const handleVerify = async () => {
      try {
        await verify(token);
      } catch (e) {
        showGenericError(e);
      }
    };
    handleVerify();
  }, [verify, token, showGenericError]);

  return (
    <Backdrop
      open
      sx={{ background: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}
    >
      <Paper elevation={10} sx={{ padding: '20px', width: '300px' }}>
        <Stack direction="column" alignItems="center" spacing={2}>
          <img width="150px" style={{ margin: '0 auto 10px' }} src={Logo} alt="strazoon Logo" />
          <CircularProgress />
        </Stack>
      </Paper>
    </Backdrop>
  );
}
