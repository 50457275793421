import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { Parcel } from '../proto/models/parcel_pb';
import { useSideDrawer } from './SideDrawer';
import ConfirmedDeletionButton from './ConfirmedDeletionButton';
import SaveAbortButtons from './SaveAbortButtons';
import AddressForm, { addressPropType } from './AddressForm';
import TimeWindowForm, { timeWindowPropType } from './TimeWindowForm';
import { createParcel, deleteParcelById, updateParcel } from '../api/parcelAdapter';
import { useError } from './ErrorContext';
import EnumMenu from './EnumMenu';
import ParcelPickerInput from './ParcelPickerInput';

export default function ParcelForm({ parcel, mode, onUpdate }) {
  const { closeSideDrawer } = useSideDrawer();
  const { showGenericError } = useError();
  const [currentParcel, setCurrentParcel] = useState(parcel);

  const onChange = (changed) => {
    setCurrentParcel({ ...currentParcel, ...changed });
  };

  const onDelete = async () => {
    try {
      const updatedDrivers = await deleteParcelById(currentParcel.id);
      onUpdate(updatedDrivers);
      closeSideDrawer();
    } catch (e) {
      showGenericError(e);
    }
  };
  const onSave = async () => {
    try {
      const updatedDrivers = (mode === 'create')
        ? await createParcel(currentParcel)
        : await updateParcel(currentParcel);
      onUpdate(updatedDrivers);
      closeSideDrawer();
    } catch (e) {
      showGenericError(e);
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h4>Sendung</h4>
        {
          mode === 'update'
          && <ConfirmedDeletionButton message={`${currentParcel.id} löschen?`} onConfirm={onDelete} />
        }
      </Stack>
      <AddressForm
        address={currentParcel.address}
        onUpdate={(address) => onChange({ address })}
      />
      <EnumMenu
        enumClass={Parcel.Status}
        i18nPrefix="parcel.status"
        label="Status"
        value={currentParcel.status}
        onChange={(e) => onChange({ status: e.target.value })}
      />
      <EnumMenu
        enumClass={Parcel.Type}
        i18nPrefix="parcel.type"
        label="Typ"
        value={currentParcel.type}
        onChange={(e) => onChange({ type: e.target.value })}
      />
      <ParcelPickerInput
        label="Verknüpft mit"
        value={currentParcel.companionId}
        blacklistedIds={[currentParcel.id]}
        onChange={(value) => onChange({ companionId: value })}
      />
      <TimeWindowForm
        timeWindow={currentParcel.timeWindow}
        onUpdate={(timeWindow) => onChange({ timeWindow })}
      />
      <TextField
        label="Kundennummer"
        margin="normal"
        value={currentParcel.customerNumber}
        onChange={(e) => onChange({ customerNumber: e.target.value })}
      />
      <TextField
        label="Barcode"
        margin="normal"
        value={currentParcel.barcode}
        onChange={(e) => onChange({ barcode: e.target.value })}
      />
      <TextField
        label="Notizen"
        margin="normal"
        value={currentParcel.notes}
        onChange={(e) => onChange({ notes: e.target.value })}
      />
      <SaveAbortButtons onSave={onSave} onAbort={closeSideDrawer} />
    </>
  );
}

// TODO: move to separate file
export const handoverPropType = PropTypes.shape({
  expectedType: PropTypes.string,
  performedType: PropTypes.string,
  performedTime: PropTypes.string,
});

export const parcelPropType = PropTypes.shape({
  id: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
  companionId: PropTypes.string,
  address: addressPropType,
  handover: handoverPropType,
  timeWindow: timeWindowPropType,
  customerNumber: PropTypes.string,
  barcode: PropTypes.string,
  notes: PropTypes.string,
});

ParcelForm.defaultProps = {
  parcel: {
    id: undefined,
    status: 'PLANNING',
    type: 'DELIVERY',
    companionId: '',
    address: {
      name: '',
      street: '',
      houseNumber: '',
      zipCode: '',
      city: '',
      country: 'DE',
    },
    handover: {
      expectedType: PropTypes.string,
      performedType: PropTypes.string,
      performedTime: PropTypes.string,
    },
    timeWindow: {
      start: null,
      end: null,
    },
    customerNumber: '',
    barcode: '',
    notes: '',
  },
  onUpdate: () => {},
};

ParcelForm.propTypes = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  parcel: parcelPropType,
  onUpdate: PropTypes.func,
};
