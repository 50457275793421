import React, { useState } from 'react';
import {
  Backdrop, Button, Divider, Paper, TextField,
} from '@mui/material';
import { NavLink, useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import background from '../images/background.jpg';
import { useError } from '../components/ErrorContext';
import { useAuth } from '../components/AuthContext';
import { provideTenant, storeTenant } from '../api/helpers';
import logAnalyticsEvent from '../utils/analytics';
import Logo from '../images/strazoon-light.svg';

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const [tenant, setTenant] = useState(searchParams.get('t') || provideTenant() || '');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { showError } = useError();
  const { login } = useAuth();

  const tryLogin = async (event) => {
    event.preventDefault();
    if (!tenant || !email || !password) {
      showError('Bitte alle Informationen angeben.');
    }
    try {
      storeTenant(tenant);
      await login(tenant, email, password);
      logAnalyticsEvent('LOGIN');
    } catch (e) {
      showError('Diese Zugangsdaten sind falsch');
      console.log(e);
    }
  };

  return (
    <Backdrop
      open
      sx={{ background: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}
    >
      <Paper elevation={10} sx={{ padding: '20px', width: '300px' }}>
        <form onSubmit={tryLogin}>
          <Stack direction="column" alignItems="stretch" spacing={2}>
            <img width="150px" style={{ margin: '0 auto 10px' }} src={Logo} alt="strazoon Logo" />
            <TextField
              id="tenant"
              label="Organisations-ID"
              value={tenant}
              onChange={(e) => setTenant(e.target.value)}
              fullWidth
            />
            <TextField
              id="username"
              label="Email-Adresse"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
            <TextField
              label="Passwort"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              fullWidth
            />
            <Button variant="contained" type="submit" fullWidth>Einloggen</Button>
            <Divider flexItem><small>oder</small></Divider>
            <NavLink to="/register" style={{ textDecoration: 'none' }}>
              <Button variant="outlined" color="black" fullWidth>
                Registrieren
              </Button>
            </NavLink>
          </Stack>
        </form>
      </Paper>
    </Backdrop>
  );
}
