import { Empty } from '../proto/models/general_pb';
import {
  arrayToObjects, iteratorToArray,
} from './helpers';
import {
  CreateDriverRequest, UpdateDriverRequest, DeleteDriverRequest, RenewDriverSignUpCodeRequest,
} from '../proto/operator/drivers_pb';
import { createServiceClient } from './createServiceClient';
import { DriverService } from '../proto/operator/drivers_pb_service';

const client = createServiceClient(DriverService);

const mapStatusToProto = (active) => (active ? 0 : 1);

const mapFromProto = (vehicle) => ({
  ...vehicle,
  status: !vehicle.status,
});

/**
 * Fetch list of drivers
 *
 * @returns {Promise<*[]>}
 */
export const listDrivers = async () => {
  const stream = client.listDrivers(new Empty());
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapFromProto);
};

/**
 * Update a driver
 *
 * @param driver object describing the new attribute values of the designated driver
 * @returns {Promise<*[]>}
 */
export async function updateDriver(driver) {
  const updateRequest = new UpdateDriverRequest();
  updateRequest.setId(driver.id);
  updateRequest.setName(driver.name);
  updateRequest.setEmail(driver.email);
  updateRequest.setStatus(mapStatusToProto(driver.status));
  const stream = client.updateDriver(updateRequest);
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapFromProto);
}

/**
 * Create a new driver
 *
 * @param driver object describing the attribute values of the new driver
 * @returns {Promise<*[]>}
 */
export async function createDriver(driver) {
  const createRequest = new CreateDriverRequest();
  createRequest.setName(driver.name);
  createRequest.setEmail(driver.email);
  createRequest.setStatus(mapStatusToProto(driver.status));
  const stream = client.createDriver(createRequest);
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapFromProto);
}

/**
 * Delete a driver
 *
 * @param driverId of the driver to delete
 * @returns {Promise<*[]>}
 */
export async function deleteDriverById(driverId) {
  const deleteRequest = new DeleteDriverRequest();
  deleteRequest.setId(driverId);
  const stream = client.deleteDriver(deleteRequest);
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapFromProto);
}

/**
 * Renew a driver's sign up code
 *
 * @param driverId of the driver to delete
 * @returns {Promise<*[]>}
 */
export async function renewSignUpCode(driverId) {
  const request = new RenewDriverSignUpCodeRequest();
  request.setId(driverId);
  const stream = client.renewSignUpCode(request);
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapFromProto);
}
