import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import React from 'react';
import PropTypes from 'prop-types';

export const nameActivityStatus = (isActive) => (isActive ? 'Aktiv' : 'Inaktiv');

export const getActivityStatusIcon = (isActive) => (isActive ? <CheckCircleIcon color="green" /> : <CancelIcon color="disabled" />);

export default function ActivityStatus({ active }) {
  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      { getActivityStatusIcon(active) }
      {' '}
      { nameActivityStatus(active) }
    </Stack>
  );
}

ActivityStatus.defaultProps = {
  active: false,
};

ActivityStatus.propTypes = {
  active: PropTypes.bool,
};
