import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAsync } from 'react-async';
import { FileUploadOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ParcelTable from '../components/ParcelTable';
import { listParcels } from '../api/parcelAdapter';
import { useSideDrawer } from '../components/SideDrawer';
import ParcelForm from '../components/ParcelForm';
import { useError } from '../components/ErrorContext';

function AddParcelButton({ onClick }) {
  return (
    <Button
      variant="contained"
      startIcon={<AddCircleIcon />}
      onClick={onClick}
    >
      Sendung hinzufügen
    </Button>
  );
}

AddParcelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default function DriverPage() {
  const { openSideDrawer } = useSideDrawer();
  const { showGenericError } = useError();

  const [parcels, setParcels] = useState([]);
  useAsync(listParcels, {
    onResolve: (updatedParcels) => setParcels(updatedParcels),
    onReject: showGenericError,
  });

  const onUpdate = (updatedParcels) => {
    setParcels(updatedParcels);
  };

  return (
    <>
      <section className="section--padding-top">
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="h3" className="no-margin">Sendungen</Typography>
          <Stack direction="row" spacing={1}>
            <Link to="/parcels-import" style={{ textDecoration: 'none' }}>
              <Button variant="contained" startIcon={<FileUploadOutlined />}>CSV importieren</Button>
            </Link>
            <AddParcelButton onClick={() => openSideDrawer(<ParcelForm mode="create" onUpdate={onUpdate} />)} />
          </Stack>
        </Stack>
      </section>
      <section className="section--padding-top">
        <ParcelTable
          parcels={parcels}
          onRowClick={(parcel) => openSideDrawer(<ParcelForm mode="update" parcel={parcel} onUpdate={onUpdate} />)}
        />
      </section>
    </>
  );
}
