import React from 'react';
import PropTypes from 'prop-types';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button } from '@mui/material';
import { CSVLink } from 'react-csv';
import { t } from 'i18next';

import { columns, historyRowPropType } from './HistoryTable';
import { enumIndexToString } from './EnumMenu';
import { Handover } from '../proto/models/parcel_pb';
import { now, dateStringOf, timeStringOf } from '../utils/datetime';
import { timeWindowToString } from './TimeWindowForm';

export default function HistoryExportButton({ parcels }) {
  const headers = columns.map((column) => ({ key: column.field, label: column.headerName }));
  const data = parcels.map((parcel) => ({
    date: dateStringOf(parcel.date),
    type: t(`parcel.type.${parcel.type}`),
    name: parcel.name,
    address: parcel.address,
    status: t(`parcel.status.${parcel.status}`),
    timeWindow: (parcel.timeWindow) ? timeWindowToString(parcel.timeWindow) : '',
    customerNumber: parcel.customerNumber ?? '',
    barcode: parcel.barcode ?? '',
    notes: parcel.notes ?? '',
    handoverType: t(`handover.type.${enumIndexToString(Handover.Type, parcel.handoverType)}`),
    handoverTime: (parcel.handoverTime) ? `${timeStringOf(parcel.handoverTime)} Uhr` : '',
  }));
  const filename = `${now().format('yyyy-MM-DD_HH-mm')}_cargobike-delivery-shipments.csv`;
  return (
    <CSVLink
      headers={headers}
      data={data}
      separator=";"
      enclosingCharacter=""
      filename={filename}
      className="btn btn-primary"
      target="_blank"
      style={{ textDecoration: 'none' }}
    >
      <Button variant="contained" startIcon={<FileDownloadIcon />}>CSV exportieren</Button>
    </CSVLink>
  );
}

HistoryExportButton.propTypes = {
  parcels: PropTypes.arrayOf(historyRowPropType).isRequired,
};
