import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';

import { t } from 'i18next';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AlarmIcon from '@mui/icons-material/Alarm';
import OutboundIcon from '@mui/icons-material/Outbound';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PersonIcon from '@mui/icons-material/Person';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tooltip, Typography } from '@mui/material';
import {
  approveTour, cancelTour, deleteTour, generateOptimizedTours, moveParcel,
} from '../api/tourAdapter';
import ConfirmedDeletionButton from './ConfirmedDeletionButton';
import { useError } from './ErrorContext';
import { useSideDrawer } from './SideDrawer';
import TourForm from './TourForm';
import { hashToHslColor } from '../utils/color';
import { timeStringOf } from '../utils/datetime';
import { ParcelTypeIcon } from './ParcelTable';
import { addressToString } from '../api/parcelAdapter';
import { timeWindowToString } from './TimeWindowForm';

const BACKLOG_ID = '__BACKLOG__';

const COLS = 6;

const getRowStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'lightgreen' : 'inherit',
  minWidth: '1000px',
  ...draggableStyle,
});
const getTableStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'inherit',
  minHeight: 50,
});

function TourParcelCells({ parcel, dragDisabled }) {
  return (
    <>
      <TableCell>
        <Stack direction="row" gap={1}>
          { !dragDisabled && <DragHandleIcon color="action" fontSize="small" sx={{ verticalAlign: 'middle' }} /> }
          <ParcelTypeIcon parcel={parcel} fontSize="small" />
        </Stack>
      </TableCell>
      <TableCell>{ parcel.address.name }</TableCell>
      <TableCell colSpan={2}>{ addressToString(parcel.address) }</TableCell>
      <TableCell>{ timeWindowToString(parcel?.timeWindow) }</TableCell>
      <TableCell>{ t(`parcel.status.${parcel.status}`) }</TableCell>
      <TableCell />
      <TableCell />
    </>
  );
}

TourParcelCells.propTypes = {
  parcel: PropTypes.any.isRequired,
  dragDisabled: PropTypes.bool.isRequired,
};

function DroppableTableBody({ droppableId, parcels, dragDisabled }) {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={dragDisabled}>
      {(provided, snapshot) => (
        <TableBody
          ref={provided.innerRef}
          style={getTableStyle(snapshot.isDraggingOver)}
          {...provided.droppableProps}
        >
          {parcels && parcels.map((parcel, index) => (
            <Draggable
              key={parcel.id}
              draggableId={parcel.id}
              index={index}
              isDragDisabled={dragDisabled}
            >
              {(provided1, snapshot1) => (
                <TableRow
                  ref={provided1.innerRef}
                  {...provided1.draggableProps}
                  {...provided1.dragHandleProps}
                  style={getRowStyle(
                    snapshot1.isDragging,
                    provided1.draggableProps.style,
                  )}
                >
                  <TourParcelCells parcel={parcel} dragDisabled={dragDisabled} />
                </TableRow>
              )}
            </Draggable>
          ))}
          <TableRow>
            <TableCell colSpan={COLS} sx={{ height: 15, borderBottom: 'none' }} />
          </TableRow>
          {provided.placeholder}
        </TableBody>
      )}
    </Droppable>
  );
}

DroppableTableBody.defaultProps = {
  parcels: [],
  dragDisabled: false,
};

DroppableTableBody.propTypes = {
  parcels: PropTypes.any,
  dragDisabled: PropTypes.bool,
  droppableId: PropTypes.string.isRequired,
};

const isTourEditable = (tour) => (tour.status === 'PLANNING');

const isTourCancelledOrCompleted = (tour) => (tour.status === 'CANCELLED' || tour.status === 'COMPLETED');

const isTourApprovable = (tour) => (tour.status === 'PLANNING' && tour.parcelsList?.length > 0 && tour.driver?.id && tour.vehicle?.id);

export default function TourOverviewTable({ data, onUpdate }) {
  const { showGenericError } = useError();
  const { openSideDrawer } = useSideDrawer();
  const [loading, setLoading] = useState(false);

  async function onDragEnd({ destination, draggableId }) {
    if (!destination) {
      return;
    }
    let { droppableId } = destination;
    if (destination.droppableId === BACKLOG_ID) {
      // droppableId of backlog is NULL when communicating with the API
      droppableId = null;
    }
    const updatedTours = await moveParcel(draggableId, droppableId, destination.index);
    onUpdate(updatedTours);
  }

  const onApprove = async (tourId) => {
    try {
      const updatedTours = await approveTour(tourId);
      onUpdate(updatedTours);
    } catch (e) {
      showGenericError(e);
    }
  };

  const onDelete = async (tourId) => {
    try {
      const updatedTours = await deleteTour(tourId);
      onUpdate(updatedTours);
    } catch (e) {
      showGenericError(e);
    }
  };

  const onCancel = async (tourId) => {
    try {
      const updatedTours = await cancelTour(tourId);
      onUpdate(updatedTours);
    } catch (e) {
      showGenericError(e);
    }
  };

  const onGenerateOptimizedTours = async () => {
    setLoading(true);
    try {
      const updatedTours = await generateOptimizedTours();
      onUpdate(updatedTours);
    } catch (e) {
      showGenericError(e);
    }
    setLoading(false);
  };

  return (
    <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
      <TableContainer sx={{ overflowX: 'inherit' }}>
        <Table size="small">
          {data && data.toursList.map((tour) => (
            <Fragment key={tour.id}>
              <TableHead
                onClick={() => openSideDrawer(
                  <TourForm tour={tour} onUpdate={onUpdate} editable={isTourEditable(tour)} />,
                )}
              >
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Stack direction="row" gap={0.5}>
                      <LocationOnIcon fontSize="small" style={{ fill: `${hashToHslColor(tour.id, '65%')}` }} />
                      {tour.parcelsList.length}
                      {' '}
                      {tour.parcelsList.length === 1 ? 'Stop' : 'Stops'}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" gap={0.5}>
                      <PersonIcon fontSize="small" color="secondary" />
                      {tour.driver?.name ? tour.driver.name : <i>Nicht definiert</i>}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" gap={0.5}>
                      <PedalBikeIcon fontSize="small" color="secondary" />
                      {tour.vehicle?.name ? tour.vehicle.name : <i>Nicht definiert</i>}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" gap={0.5}>
                      <ScheduleIcon fontSize="small" color="secondary" />
                      { t('timeWindow') }
                    </Stack>
                  </TableCell>
                  <TableCell>{ t(`tour.status.${tour.status}`) }</TableCell>
                  {
                    tour.startatdatetime
                      ? (
                        <TableCell>
                          <Tooltip title={`Empfohlene Startzeit: ${timeStringOf(tour.startatdatetime)} Uhr`}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={0.5}>
                              <AlarmIcon fontSize="small" color="secondary" />
                              {timeStringOf(tour.startatdatetime)}
                            </Stack>
                          </Tooltip>
                        </TableCell>
                      )
                      : <TableCell />
                  }
                  <TableCell width="auto">
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={0.5}>
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={isTourEditable(tour) ? <OutboundIcon /> : <CheckCircleIcon />}
                        onClick={(e) => { e.stopPropagation(); onApprove(tour.id); }}
                        disabled={!isTourApprovable(tour)}
                      >
                        {isTourEditable(tour) ? 'Freigeben' : 'Freigegeben'}
                      </Button>
                      {isTourEditable(tour)
                        ? (
                          <ConfirmedDeletionButton
                            message="Möchten Sie diese Tour löschen? Sendungen landen wieder in &quot;Unverplante Sendungen&quot;"
                            onConfirm={() => onDelete(tour.id)}
                          />
                        )
                        : (
                          <ConfirmedDeletionButton
                            message="Möchten Sie diese Tour abbrechen? Offene Sendungen landen wieder in &quot;Unverplante Sendungen&quot;"
                            onConfirm={() => onCancel(tour.id)}
                            title="Abbrechen"
                            icon={<CancelIcon />}
                            disabled={isTourCancelledOrCompleted(tour)}
                          />
                        )}
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <DroppableTableBody
                droppableId={tour.id}
                parcels={tour.parcelsList}
                dragDisabled={!isTourEditable(tour)}
              />
            </Fragment>
          ))}
          <TableBody>
            <TableRow>
              <TableCell colSpan={COLS} align="center" sx={{ pb: 5, borderBottom: 'none' }}>
                <Button
                  variant="contained"
                  size="large"
                  startIcon={<SwapVerticalCircleIcon />}
                  onClick={() => onGenerateOptimizedTours()}
                  disabled={loading || !data || data.backlogList.length === 0}
                >
                  Sendungen automatisch zuordnen
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell colSpan={COLS} sx={{ borderBottom: 'none' }}>
                <Typography variant="h4" sx={{ marginTop: 0, marginBottom: '1em' }}>
                  Unverplante Sendungen
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
          <DroppableTableBody droppableId={BACKLOG_ID} parcels={data?.backlogList} />
        </Table>
      </TableContainer>
    </DragDropContext>
  );
}

TourOverviewTable.defaultProps = {
  data: null,
};

TourOverviewTable.propTypes = {
  data: PropTypes.any,
  onUpdate: PropTypes.func.isRequired,
};
