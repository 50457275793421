import moment from 'moment';

const dateFormat = 'DD.MM.YYYY';
const timeFormat = 'HH:mm';

const dateTimeStringOf = (dateTime, format) => {
  if (!dateTime) return null;
  let dateTimeMoment = moment(dateTime);
  if (!dateTimeMoment.isValid()) {
    dateTimeMoment = moment(dateTime.slice(0, dateTime.indexOf('[')));
  }
  const formatted = dateTimeMoment.format(format);
  return formatted;
};

export const now = () => moment();

export const nowString = () => now().format();

export const dateStringOf = (dateTime) => dateTimeStringOf(dateTime, dateFormat);

export const timeStringOf = (dateTime) => dateTimeStringOf(dateTime, timeFormat);
