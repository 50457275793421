import React, { useEffect, useState } from 'react';
import {
  Backdrop, Button, Checkbox, Divider, FormControlLabel, FormGroup, Paper, TextField, Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PersonIcon from '@mui/icons-material/Person';
import Stack from '@mui/material/Stack';
import { NavLink } from 'react-router-dom';
import background from '../images/background.jpg';
import { useError } from '../components/ErrorContext';
import logAnalyticsEvent from '../utils/analytics';
import Logo from '../images/strazoon-light.svg';
import MapWithPointer from '../images/map-with-pointer.svg';
import AddressForm from '../components/AddressForm';
import { register } from '../api/loginAdapter';
import { storeTenant } from '../api/helpers';

export default function RegisterPage() {
  const [step, setStep] = useState(1);
  const [tenantName, setTenantName] = useState('');
  const [tenantAcronym, setTenantAcronym] = useState('');
  const [depotName] = useState('Mein Depot');
  const [depotAddress, setDepotAddress] = useState(null);
  const [maintainerName, setMaintainerName] = useState('');
  const [maintainerEmail, setMaintainerEmail] = useState('');
  const [maintainerPassword, setMaintainerPassword] = useState('');
  const [maintainerPassword2, setMaintainerPassword2] = useState('');
  const [terms, setTerms] = useState(false);
  const { showGenericError } = useError();

  const generateIdFromTenantName = (name) => name
    .toLowerCase()
    .trim()
    .replace(/[\s-]/g, '')
    .replace(/^-+|-+$/g, '')
    .substring(0, 12);

  useEffect(() => {
    setTenantAcronym(generateIdFromTenantName(tenantName));
  }, [tenantName]);

  const hasPasswordError = (password) => (password.length > 0 && password.length < 8);

  const isForm1Complete = () => tenantName
    && tenantAcronym
    && maintainerName
    && maintainerEmail
    && maintainerPassword
    && !hasPasswordError(maintainerPassword)
    && maintainerPassword2
    && (maintainerPassword === maintainerPassword2)
    && terms;

  const isForm2Complete = () => !!depotAddress;

  // Dry Run Registration in step 1
  const dryRunRegistration = async () => {
    const depotAddressWithName = { ...depotAddress, name: depotName };
    try {
      await register(
        tenantName,
        tenantAcronym,
        depotName,
        depotAddressWithName, // <-- This will be empty on purpose
        maintainerName,
        maintainerEmail,
        maintainerPassword,
      );
    } catch (e) {
      if (e.details === 'In der Adresse fehlt die Straße') {
        // This is expected, because address is set in step 2
        setStep(2);
      } else {
        // An (actual) unexpected error occurred
        showGenericError(e);
      }
    }
  };

  // Finalize registration in step 2
  const finalizeRegistration = async () => {
    const depotAddressWithName = { ...depotAddress, name: depotName };
    try {
      await register(
        tenantName,
        tenantAcronym,
        depotName,
        depotAddressWithName,
        maintainerName,
        maintainerEmail,
        maintainerPassword,
      );
      storeTenant(tenantAcronym);
      logAnalyticsEvent('REGISTER');
      setStep(3);
    } catch (e) {
      showGenericError(e);
    }
  };

  return (
    <Backdrop
      open
      sx={{ background: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}
    >
      <Paper elevation={10} sx={{ padding: '20px', width: '350px' }}>
        {(step === 1) && (
          <Stack direction="column" alignItems="stretch" spacing={2}>
            <img width="150px" style={{ margin: '10px auto' }} src={Logo} alt="strazoon Logo" />
            <TextField
              label="Organisationsname"
              value={tenantName}
              onChange={(e) => setTenantName(e.target.value)}
              fullWidth
            />
            <TextField
              label="Organisations-ID"
              value={tenantAcronym}
              onChange={(e) => setTenantAcronym(e.target.value)}
              fullWidth
              helperText="Die Organisations-ID wird später für den Login benötigt"
            />
            <Divider flexItem><PersonIcon /></Divider>
            <TextField
              label="Name"
              value={maintainerName}
              onChange={(e) => setMaintainerName(e.target.value)}
              fullWidth
            />
            <TextField
              label="Email-Adresse"
              value={maintainerEmail}
              onChange={(e) => setMaintainerEmail(e.target.value)}
              type="email"
              fullWidth
            />
            <TextField
              label="Passwort"
              value={maintainerPassword}
              onChange={(e) => setMaintainerPassword(e.target.value)}
              type="password"
              fullWidth
              error={hasPasswordError(maintainerPassword)}
              helperText={hasPasswordError(maintainerPassword) ? 'Das Passwort muss mindestens 8 Zeichen lang sein' : ''}
            />
            <TextField
              label="Passwort erneut eingeben"
              value={maintainerPassword2}
              onChange={(e) => setMaintainerPassword2(e.target.value)}
              type="password"
              fullWidth
              error={maintainerPassword !== maintainerPassword2}
              helperText={(maintainerPassword !== maintainerPassword2) ? 'Die Passwörter müssen übereinstimmen' : ''}
            />
            <FormGroup sx={{ px: 0.5 }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={terms}
                    onChange={(e) => setTerms(e.target.checked)}
                  />
                )}
                label={(
                  <span>
                    Ich akzeptiere die
                    {' '}
                    <a target="_blank" rel="noreferrer" href="http://strazoon.com/privacy/cargobike">
                      Allgemeinen Geschäftsbedingungen
                    </a>
                    {' '}
                    und
                    {' '}
                    <a target="_blank" rel="noreferrer" href="https://strazoon.com/datenschutz">
                      Datenschutzhinweise
                    </a>
                    .
                  </span>
                )}
              />
            </FormGroup>
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <NavLink to="/login" style={{ textDecoration: 'none' }}>
                <Button variant="outlined" color="black" startIcon={<ArrowBackIosIcon />}>
                  Zurück
                </Button>
              </NavLink>
              <Button onClick={dryRunRegistration} variant="contained" disabled={!isForm1Complete()} fullWidth>Registrieren</Button>
            </Stack>
          </Stack>
        )}
        {(step === 2) && (
          <Stack direction="column" alignItems="stretch" spacing={2}>
            <img width="150px" style={{ margin: '10px auto 15px' }} src={Logo} alt="strazoon Logo" />
            <Divider flexItem>Wähle ein Depot</Divider>
            <AddressForm
              onUpdate={(address) => setDepotAddress(address)}
              disableDepotRadius
              hideName
            />
            <Stack direction="row" spacing={2} sx={{ py: 2 }}>
              <img width="120px" src={MapWithPointer} alt="Map with pointer" />
              <small>
                Das Depot ist der zentrale Ort, von dem aus Bestellungen ausgeliefert werden.
                Wenn es einen solchen Ort nicht gibt, kann auch eine beliebige Adresse in
                zentraler Lage des Liefergebietes gewählt werden.
              </small>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Button onClick={() => setStep(1)} variant="outlined" color="black" startIcon={<ArrowBackIosIcon />}>
                Zurück
              </Button>
              <Button onClick={finalizeRegistration} variant="contained" disabled={!isForm2Complete()} fullWidth>Registrieren</Button>
            </Stack>
          </Stack>
        )}
        {(step === 3) && (
          <Stack direction="column" alignItems="stretch" spacing={2}>
            <img width="150px" style={{ margin: '0 auto 10px' }} src={Logo} alt="strazoon Logo" />
            <h3>Fast geschafft!</h3>
            <Typography component="span">
              Wir haben Dir eine E-Mail an
              {' '}
              { maintainerEmail }
              {' '}
              geschickt, um die Registrierung zu bestätigen.
            </Typography>
          </Stack>
        )}
      </Paper>
    </Backdrop>
  );
}
