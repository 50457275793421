import React, { useState } from 'react';
import {
  Button, Stack, CircularProgress, Chip, Typography, Link,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { InsertDriveFile } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import parseCSV from '../utils/parseCSV';
import { useError } from '../components/ErrorContext';
import { addressToString, createParcelBatch } from '../api/parcelAdapter';
import sampleCSV from '../data/sample.csv';
import { radiusAddressComplete } from '../api/utilAdapter';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'street',
    headerName: 'Straße',
    flex: 1,
  },
  {
    field: 'houseNumber',
    headerName: 'Hausnummer',
    flex: 1,
  },
  {
    field: 'zipCode',
    headerName: 'PLZ',
    flex: 1,
  },
  {
    field: 'city',
    headerName: 'Stadt',
    flex: 1,
  },
  {
    field: 'country',
    headerName: 'Land',
    flex: 1,
  },
  {
    field: 'start',
    headerName: 'Zeitf. Start',
    flex: 1,
  },
  {
    field: 'end',
    headerName: 'Zeitf. Ende',
    flex: 1,
  },
  {
    field: 'customerNumber',
    headerName: 'Kundennummer',
    flex: 1,
  },
  {
    field: 'barcode',
    headerName: 'Barcode',
    flex: 1,
  },
  {
    field: 'notes',
    headerName: 'Notizen',
    flex: 1,
  },
];

export default function ParcelUploadPage() {
  const [parcels, setParcels] = useState([]);
  const [reading, setReading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { showError, showGenericError } = useError();
  const navigate = useNavigate();

  const handleFiles = async (files) => {
    const reader = new FileReader();
    reader.addEventListener('load', async () => {
      const addresses = await Promise.all(
        parseCSV(reader.result).map(async (line) => {
          const item = { ...line };
          const addressText = addressToString(line);
          try {
            const value = await radiusAddressComplete(addressText);
            if (value.length > 0) {
              const address = value[0];
              item.latitude = address.location.latitude;
              item.longitude = address.location.longitude;
              item.status = 'default';
            } else {
              showError(`address not found: ${addressText}`);
              item.error = `address not found: ${addressText}`;
              item.status = 'error';
            }
          } catch (e) {
            showError(e, `address search error: ${addressText}`);
            item.error = `address search error: ${addressText}`;
            item.status = 'error';
          }
          return item;
        }),
      );
      setParcels(addresses);
      setReading(false);
    }, false);
    reader.addEventListener('error', () => {
      showError('.csv-Datei einlesen fehlgeschlagen');
      setReading(false);
    }, false);
    if (files.length > 0) {
      setReading(true);
      reader.readAsText(files[0]);
    }
  };

  const save = async () => {
    try {
      setSaving(true);
      await createParcelBatch(parcels);
      navigate('/parcels');
    } catch (e) {
      showGenericError(e);
      setSaving(false);
    }
  };

  return (
    <>
      <section className="section--padding-top">
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="h3" className="no-margin">Sendungen importieren</Typography>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" color="secondary" startIcon={<FileDownloadIcon />} component={Link} href={sampleCSV} download="sample.csv">
              Beispiel herunterladen
            </Button>
            <CircularProgress style={{ display: reading ? 'block' : 'none' }} size="2rem" />
            <label htmlFor="upload-file">
              <input
                style={{ display: 'none' }}
                id="upload-file"
                name="upload-file"
                type="file"
                accept=".csv"
                onChange={(e) => handleFiles(e.target.files)}
              />
              <Button variant="contained" component="span" disabled={reading} startIcon={<InsertDriveFile />}>
                .csv-Datei auswählen
              </Button>
            </label>
          </Stack>
        </Stack>
      </section>
      <section>
        <Typography variant="subtitle2" gutterBottom component="div">
          CSV-Format
        </Typography>
        Zellen werden mit Komma, Semikolon oder Tab getrennt.
        Erste Zeile als Titelzeile, Titelreihenfolge beliebig, unterstützte Spaltentitel:
        <br />
        {
          columns.map(({ field }) => (<Chip key={field} label={field} variant="outlined" size="small" />))
        }
      </section>
      <section className="section--padding-top">
        <DataGrid
          style={{ height: 400 }}
          rows={parcels}
          columns={columns}
          getRowClassName={(params) => `${params.row.status}`}
          sx={{
            '& .default': {},
            '& .error': {
              color: (theme) => `${theme.palette.text.disabled}`,
              bgcolor: (theme) => `${theme.palette.red.main}30`,
              '&:hover': {
                bgcolor: (theme) => `${theme.palette.red.main}40`,
              },
            },
          }}
        />
      </section>
      <section>
        <Button variant="contained" disabled={!parcels.length || saving || parcels.filter((parcel) => 'error' in parcel).length > 0} onClick={save}>Speichern</Button>
      </section>
    </>
  );
}
