// source: operator/parcels.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var models_general_pb = require('../models/general_pb.js');
goog.object.extend(proto, models_general_pb);
var models_parcel_pb = require('../models/parcel_pb.js');
goog.object.extend(proto, models_parcel_pb);
goog.exportSymbol('proto.gridos.proto.operator.CreateParcelRequest', null, global);
goog.exportSymbol('proto.gridos.proto.operator.DeleteParcelRequest', null, global);
goog.exportSymbol('proto.gridos.proto.operator.UpdateParcelRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.operator.CreateParcelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.operator.CreateParcelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.operator.CreateParcelRequest.displayName = 'proto.gridos.proto.operator.CreateParcelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.operator.UpdateParcelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.operator.UpdateParcelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.operator.UpdateParcelRequest.displayName = 'proto.gridos.proto.operator.UpdateParcelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.operator.DeleteParcelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.operator.DeleteParcelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.operator.DeleteParcelRequest.displayName = 'proto.gridos.proto.operator.DeleteParcelRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.operator.CreateParcelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.operator.CreateParcelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.CreateParcelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    companionid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: (f = msg.getAddress()) && models_general_pb.Address.toObject(includeInstance, f),
    handovertype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    timewindow: (f = msg.getTimewindow()) && models_parcel_pb.TimeWindow.toObject(includeInstance, f),
    barcode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    customernumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.operator.CreateParcelRequest}
 */
proto.gridos.proto.operator.CreateParcelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.operator.CreateParcelRequest;
  return proto.gridos.proto.operator.CreateParcelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.operator.CreateParcelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.operator.CreateParcelRequest}
 */
proto.gridos.proto.operator.CreateParcelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.gridos.proto.models.Parcel.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {!proto.gridos.proto.models.Parcel.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanionid(value);
      break;
    case 4:
      var value = new models_general_pb.Address;
      reader.readMessage(value,models_general_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {!proto.gridos.proto.models.Handover.Type} */ (reader.readEnum());
      msg.setHandovertype(value);
      break;
    case 6:
      var value = new models_parcel_pb.TimeWindow;
      reader.readMessage(value,models_parcel_pb.TimeWindow.deserializeBinaryFromReader);
      msg.setTimewindow(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBarcode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomernumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.operator.CreateParcelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.operator.CreateParcelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.CreateParcelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCompanionid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      models_general_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getHandovertype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTimewindow();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      models_parcel_pb.TimeWindow.serializeBinaryToWriter
    );
  }
  f = message.getBarcode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCustomernumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional gridos.proto.models.Parcel.Status status = 1;
 * @return {!proto.gridos.proto.models.Parcel.Status}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.getStatus = function() {
  return /** @type {!proto.gridos.proto.models.Parcel.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.gridos.proto.models.Parcel.Status} value
 * @return {!proto.gridos.proto.operator.CreateParcelRequest} returns this
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional gridos.proto.models.Parcel.Type type = 2;
 * @return {!proto.gridos.proto.models.Parcel.Type}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.getType = function() {
  return /** @type {!proto.gridos.proto.models.Parcel.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.gridos.proto.models.Parcel.Type} value
 * @return {!proto.gridos.proto.operator.CreateParcelRequest} returns this
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string companionID = 3;
 * @return {string}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.getCompanionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.CreateParcelRequest} returns this
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.setCompanionid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional gridos.proto.models.Address address = 4;
 * @return {?proto.gridos.proto.models.Address}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.getAddress = function() {
  return /** @type{?proto.gridos.proto.models.Address} */ (
    jspb.Message.getWrapperField(this, models_general_pb.Address, 4));
};


/**
 * @param {?proto.gridos.proto.models.Address|undefined} value
 * @return {!proto.gridos.proto.operator.CreateParcelRequest} returns this
*/
proto.gridos.proto.operator.CreateParcelRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gridos.proto.operator.CreateParcelRequest} returns this
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional gridos.proto.models.Handover.Type handoverType = 5;
 * @return {!proto.gridos.proto.models.Handover.Type}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.getHandovertype = function() {
  return /** @type {!proto.gridos.proto.models.Handover.Type} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.gridos.proto.models.Handover.Type} value
 * @return {!proto.gridos.proto.operator.CreateParcelRequest} returns this
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.setHandovertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional gridos.proto.models.TimeWindow timeWindow = 6;
 * @return {?proto.gridos.proto.models.TimeWindow}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.getTimewindow = function() {
  return /** @type{?proto.gridos.proto.models.TimeWindow} */ (
    jspb.Message.getWrapperField(this, models_parcel_pb.TimeWindow, 6));
};


/**
 * @param {?proto.gridos.proto.models.TimeWindow|undefined} value
 * @return {!proto.gridos.proto.operator.CreateParcelRequest} returns this
*/
proto.gridos.proto.operator.CreateParcelRequest.prototype.setTimewindow = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gridos.proto.operator.CreateParcelRequest} returns this
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.clearTimewindow = function() {
  return this.setTimewindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.hasTimewindow = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string barcode = 7;
 * @return {string}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.getBarcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.CreateParcelRequest} returns this
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.setBarcode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string customerNumber = 8;
 * @return {string}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.getCustomernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.CreateParcelRequest} returns this
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.setCustomernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string notes = 9;
 * @return {string}
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.CreateParcelRequest} returns this
 */
proto.gridos.proto.operator.CreateParcelRequest.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.operator.UpdateParcelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.operator.UpdateParcelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.UpdateParcelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    companionid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: (f = msg.getAddress()) && models_general_pb.Address.toObject(includeInstance, f),
    handovertype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timewindow: (f = msg.getTimewindow()) && models_parcel_pb.TimeWindow.toObject(includeInstance, f),
    barcode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    customernumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest}
 */
proto.gridos.proto.operator.UpdateParcelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.operator.UpdateParcelRequest;
  return proto.gridos.proto.operator.UpdateParcelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.operator.UpdateParcelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest}
 */
proto.gridos.proto.operator.UpdateParcelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.gridos.proto.models.Parcel.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {!proto.gridos.proto.models.Parcel.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanionid(value);
      break;
    case 5:
      var value = new models_general_pb.Address;
      reader.readMessage(value,models_general_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {!proto.gridos.proto.models.Handover.Type} */ (reader.readEnum());
      msg.setHandovertype(value);
      break;
    case 7:
      var value = new models_parcel_pb.TimeWindow;
      reader.readMessage(value,models_parcel_pb.TimeWindow.deserializeBinaryFromReader);
      msg.setTimewindow(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBarcode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomernumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.operator.UpdateParcelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.operator.UpdateParcelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.UpdateParcelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCompanionid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      models_general_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getHandovertype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTimewindow();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      models_parcel_pb.TimeWindow.serializeBinaryToWriter
    );
  }
  f = message.getBarcode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCustomernumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest} returns this
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional gridos.proto.models.Parcel.Status status = 2;
 * @return {!proto.gridos.proto.models.Parcel.Status}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.getStatus = function() {
  return /** @type {!proto.gridos.proto.models.Parcel.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.gridos.proto.models.Parcel.Status} value
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest} returns this
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional gridos.proto.models.Parcel.Type type = 3;
 * @return {!proto.gridos.proto.models.Parcel.Type}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.getType = function() {
  return /** @type {!proto.gridos.proto.models.Parcel.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.gridos.proto.models.Parcel.Type} value
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest} returns this
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string companionID = 4;
 * @return {string}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.getCompanionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest} returns this
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.setCompanionid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional gridos.proto.models.Address address = 5;
 * @return {?proto.gridos.proto.models.Address}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.getAddress = function() {
  return /** @type{?proto.gridos.proto.models.Address} */ (
    jspb.Message.getWrapperField(this, models_general_pb.Address, 5));
};


/**
 * @param {?proto.gridos.proto.models.Address|undefined} value
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest} returns this
*/
proto.gridos.proto.operator.UpdateParcelRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest} returns this
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional gridos.proto.models.Handover.Type handoverType = 6;
 * @return {!proto.gridos.proto.models.Handover.Type}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.getHandovertype = function() {
  return /** @type {!proto.gridos.proto.models.Handover.Type} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.gridos.proto.models.Handover.Type} value
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest} returns this
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.setHandovertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional gridos.proto.models.TimeWindow timeWindow = 7;
 * @return {?proto.gridos.proto.models.TimeWindow}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.getTimewindow = function() {
  return /** @type{?proto.gridos.proto.models.TimeWindow} */ (
    jspb.Message.getWrapperField(this, models_parcel_pb.TimeWindow, 7));
};


/**
 * @param {?proto.gridos.proto.models.TimeWindow|undefined} value
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest} returns this
*/
proto.gridos.proto.operator.UpdateParcelRequest.prototype.setTimewindow = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest} returns this
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.clearTimewindow = function() {
  return this.setTimewindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.hasTimewindow = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string barcode = 8;
 * @return {string}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.getBarcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest} returns this
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.setBarcode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string customerNumber = 9;
 * @return {string}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.getCustomernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest} returns this
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.setCustomernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string notes = 10;
 * @return {string}
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.UpdateParcelRequest} returns this
 */
proto.gridos.proto.operator.UpdateParcelRequest.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.operator.DeleteParcelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.operator.DeleteParcelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.operator.DeleteParcelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.DeleteParcelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.operator.DeleteParcelRequest}
 */
proto.gridos.proto.operator.DeleteParcelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.operator.DeleteParcelRequest;
  return proto.gridos.proto.operator.DeleteParcelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.operator.DeleteParcelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.operator.DeleteParcelRequest}
 */
proto.gridos.proto.operator.DeleteParcelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.operator.DeleteParcelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.operator.DeleteParcelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.operator.DeleteParcelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.DeleteParcelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.gridos.proto.operator.DeleteParcelRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.DeleteParcelRequest} returns this
 */
proto.gridos.proto.operator.DeleteParcelRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.gridos.proto.operator);
