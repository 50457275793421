// package: gridos.proto.operator
// file: operator/tours.proto

var operator_tours_pb = require("../operator/tours_pb");
var models_general_pb = require("../models/general_pb");
var models_tour_pb = require("../models/tour_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TourService = (function () {
  function TourService() {}
  TourService.serviceName = "gridos.proto.operator.TourService";
  return TourService;
}());

TourService.ListCurrentTours = {
  methodName: "ListCurrentTours",
  service: TourService,
  requestStream: false,
  responseStream: false,
  requestType: models_general_pb.Empty,
  responseType: operator_tours_pb.TourListReply
};

TourService.ListAllTours = {
  methodName: "ListAllTours",
  service: TourService,
  requestStream: false,
  responseStream: true,
  requestType: models_general_pb.Empty,
  responseType: models_tour_pb.Tour
};

TourService.CreateTour = {
  methodName: "CreateTour",
  service: TourService,
  requestStream: false,
  responseStream: false,
  requestType: models_general_pb.Empty,
  responseType: operator_tours_pb.TourListReply
};

TourService.UpdateTour = {
  methodName: "UpdateTour",
  service: TourService,
  requestStream: false,
  responseStream: false,
  requestType: operator_tours_pb.UpdateTourRequest,
  responseType: operator_tours_pb.TourListReply
};

TourService.MoveParcel = {
  methodName: "MoveParcel",
  service: TourService,
  requestStream: false,
  responseStream: false,
  requestType: operator_tours_pb.MoveParcelRequest,
  responseType: operator_tours_pb.TourListReply
};

TourService.ApproveTour = {
  methodName: "ApproveTour",
  service: TourService,
  requestStream: false,
  responseStream: false,
  requestType: operator_tours_pb.ApproveTourRequest,
  responseType: operator_tours_pb.TourListReply
};

TourService.CancelTour = {
  methodName: "CancelTour",
  service: TourService,
  requestStream: false,
  responseStream: false,
  requestType: operator_tours_pb.CancelTourRequest,
  responseType: operator_tours_pb.TourListReply
};

TourService.DeleteTour = {
  methodName: "DeleteTour",
  service: TourService,
  requestStream: false,
  responseStream: false,
  requestType: operator_tours_pb.DeleteTourRequest,
  responseType: operator_tours_pb.TourListReply
};

TourService.GenerateOptimizedTours = {
  methodName: "GenerateOptimizedTours",
  service: TourService,
  requestStream: false,
  responseStream: false,
  requestType: models_general_pb.Empty,
  responseType: operator_tours_pb.TourListReply
};

exports.TourService = TourService;

function TourServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TourServiceClient.prototype.listCurrentTours = function listCurrentTours(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TourService.ListCurrentTours, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TourServiceClient.prototype.listAllTours = function listAllTours(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(TourService.ListAllTours, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

TourServiceClient.prototype.createTour = function createTour(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TourService.CreateTour, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TourServiceClient.prototype.updateTour = function updateTour(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TourService.UpdateTour, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TourServiceClient.prototype.moveParcel = function moveParcel(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TourService.MoveParcel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TourServiceClient.prototype.approveTour = function approveTour(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TourService.ApproveTour, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TourServiceClient.prototype.cancelTour = function cancelTour(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TourService.CancelTour, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TourServiceClient.prototype.deleteTour = function deleteTour(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TourService.DeleteTour, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TourServiceClient.prototype.generateOptimizedTours = function generateOptimizedTours(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TourService.GenerateOptimizedTours, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TourServiceClient = TourServiceClient;

