import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PersonIcon from '@mui/icons-material/Person';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import DirectionsIcon from '@mui/icons-material/Directions';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import GavelIcon from '@mui/icons-material/Gavel';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { Typography } from '@mui/material';
import Logo from '../images/strazoon-light.svg';
import Theme from './Theme';
import { SideDrawerProvider } from '../components/SideDrawer';
import { ErrorProvider } from '../components/ErrorContext';
import { AuthProvider } from '../components/AuthContext';
import UserInfo from '../components/UserInfo';
import logAnalyticsEvent from '../utils/analytics';

export default function BaseLayout({ children }) {
  logAnalyticsEvent('PAGE_LOADED');
  return (
    <ThemeProvider theme={Theme}>
      <AuthProvider>
        <ErrorProvider>
          <SideDrawerProvider>
            <header className="header">
              <img className="header__logo" src={Logo} alt="strazoon Logo" />
              <Typography className="header__title" variant="h1" fontSize="1.5rem">
                Cargobike Delivery
              </Typography>
              <Typography variant="h1" fontSize="1.5rem">
                <sup>
                  <sup>
                    <sup>
                      beta
                    </sup>
                  </sup>
                </sup>
              </Typography>
              <UserInfo />
            </header>
            <aside className="sidebar">
              <NavLink to="/">
                <CalendarMonthIcon />
  &ensp;Planung
              </NavLink>
              <NavLink to="/parcels">
                <ForwardToInboxIcon />
  &ensp;Sendungen
              </NavLink>
              <NavLink to="/drivers">
                <PersonIcon />
  &ensp;Personal
              </NavLink>
              <NavLink to="/vehicles">
                <PedalBikeIcon />
  &ensp;Fahrzeuge
              </NavLink>
              <NavLink to="/route">
                <DirectionsIcon />
  &ensp;Routenhinweise
              </NavLink>
              <NavLink to="/statistics">
                <BarChartIcon />
  &ensp;Statistik
              </NavLink>
              <NavLink to="/settings">
                <SettingsIcon />
  &ensp;Einstellungen
              </NavLink>
              <a target="_blank" rel="noreferrer" href="https://strazoon.com/terms/cargobike/">
                <FactCheckIcon />
                &ensp;AGB
              </a>
              <a target="_blank" rel="noreferrer" href="https://strazoon.com/impressum">
                <GavelIcon />
                &ensp;Impressum
              </a>
            </aside>
            <main className="main">
              {children}
              <footer className="footer" />
            </main>
          </SideDrawerProvider>
        </ErrorProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
