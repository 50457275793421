import { AddressCompleteRequest } from '../proto/operator/utils_pb';
import { createServiceClient } from './createServiceClient';
import { UtilsService } from '../proto/operator/utils_pb_service';
import { arrayToObjects, iteratorToArray } from './helpers';
import { mapAddressFromProto } from './parcelAdapter';

const client = createServiceClient(UtilsService);

export const addressComplete = async (inputText) => {
  const request = new AddressCompleteRequest();
  request.setAddresstext(inputText);
  const stream = await client.addressComplete(request);
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapAddressFromProto);
};

export const radiusAddressComplete = async (inputText) => {
  const request = new AddressCompleteRequest();
  request.setAddresstext(inputText);
  const stream = await client.radiusAddressComplete(request);
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapAddressFromProto);
};
