// package: gridos.proto.operator
// file: operator/parcels.proto

var operator_parcels_pb = require("../operator/parcels_pb");
var models_general_pb = require("../models/general_pb");
var models_parcel_pb = require("../models/parcel_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ParcelService = (function () {
  function ParcelService() {}
  ParcelService.serviceName = "gridos.proto.operator.ParcelService";
  return ParcelService;
}());

ParcelService.ListParcels = {
  methodName: "ListParcels",
  service: ParcelService,
  requestStream: false,
  responseStream: true,
  requestType: models_general_pb.Empty,
  responseType: models_parcel_pb.Parcel
};

ParcelService.CreateParcel = {
  methodName: "CreateParcel",
  service: ParcelService,
  requestStream: false,
  responseStream: true,
  requestType: operator_parcels_pb.CreateParcelRequest,
  responseType: models_parcel_pb.Parcel
};

ParcelService.CreateBatch = {
  methodName: "CreateBatch",
  service: ParcelService,
  requestStream: true,
  responseStream: false,
  requestType: operator_parcels_pb.CreateParcelRequest,
  responseType: models_general_pb.Empty
};

ParcelService.UpdateParcel = {
  methodName: "UpdateParcel",
  service: ParcelService,
  requestStream: false,
  responseStream: true,
  requestType: operator_parcels_pb.UpdateParcelRequest,
  responseType: models_parcel_pb.Parcel
};

ParcelService.DeleteParcel = {
  methodName: "DeleteParcel",
  service: ParcelService,
  requestStream: false,
  responseStream: true,
  requestType: operator_parcels_pb.DeleteParcelRequest,
  responseType: models_parcel_pb.Parcel
};

exports.ParcelService = ParcelService;

function ParcelServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ParcelServiceClient.prototype.listParcels = function listParcels(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ParcelService.ListParcels, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ParcelServiceClient.prototype.createParcel = function createParcel(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ParcelService.CreateParcel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ParcelServiceClient.prototype.createBatch = function createBatch(metadata) {
  var listeners = {
    end: [],
    status: []
  };
  var client = grpc.client(ParcelService.CreateBatch, {
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport
  });
  client.onEnd(function (status, statusMessage, trailers) {
    listeners.status.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners.end.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners = null;
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    write: function (requestMessage) {
      if (!client.started) {
        client.start(metadata);
      }
      client.send(requestMessage);
      return this;
    },
    end: function () {
      client.finishSend();
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ParcelServiceClient.prototype.updateParcel = function updateParcel(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ParcelService.UpdateParcel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ParcelServiceClient.prototype.deleteParcel = function deleteParcel(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ParcelService.DeleteParcel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.ParcelServiceClient = ParcelServiceClient;

