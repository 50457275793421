import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const enumToDataGridValueOptions = (enumClass) => Object.keys(enumClass).map((key) => ({
  value: key,
  label: key,
}));

export const enumIndexToString = (enumClass, index) => Object.keys(enumClass)[index];

export const enumStringToIndex = (enumClass, str) => enumClass[str];

export default function EnumMenu({
  enumClass, i18nPrefix, label, value, onChange,
}) {
  const { t } = useTranslation('translation', { keyPrefix: i18nPrefix });
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      margin="normal"
      select
    >
      {
        Object.keys(enumClass).map((key) => (
          <MenuItem key={key} value={key}>{t(key)}</MenuItem>
        ))
      }
    </TextField>
  );
}

EnumMenu.propTypes = {
  enumClass: PropTypes.object.isRequired,
  i18nPrefix: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
