// package: gridos.proto.operator
// file: operator/vehicles.proto

var operator_vehicles_pb = require("../operator/vehicles_pb");
var models_general_pb = require("../models/general_pb");
var models_vehicle_pb = require("../models/vehicle_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var VehicleService = (function () {
  function VehicleService() {}
  VehicleService.serviceName = "gridos.proto.operator.VehicleService";
  return VehicleService;
}());

VehicleService.listVehicles = {
  methodName: "listVehicles",
  service: VehicleService,
  requestStream: false,
  responseStream: true,
  requestType: models_general_pb.Empty,
  responseType: models_vehicle_pb.Vehicle
};

VehicleService.createVehicle = {
  methodName: "createVehicle",
  service: VehicleService,
  requestStream: false,
  responseStream: true,
  requestType: operator_vehicles_pb.CreateVehicleRequest,
  responseType: models_vehicle_pb.Vehicle
};

VehicleService.updateVehicle = {
  methodName: "updateVehicle",
  service: VehicleService,
  requestStream: false,
  responseStream: true,
  requestType: operator_vehicles_pb.UpdateVehicleRequest,
  responseType: models_vehicle_pb.Vehicle
};

VehicleService.deleteVehicle = {
  methodName: "deleteVehicle",
  service: VehicleService,
  requestStream: false,
  responseStream: true,
  requestType: operator_vehicles_pb.DeleteVehicleRequest,
  responseType: models_vehicle_pb.Vehicle
};

exports.VehicleService = VehicleService;

function VehicleServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

VehicleServiceClient.prototype.listVehicles = function listVehicles(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(VehicleService.listVehicles, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

VehicleServiceClient.prototype.createVehicle = function createVehicle(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(VehicleService.createVehicle, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

VehicleServiceClient.prototype.updateVehicle = function updateVehicle(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(VehicleService.updateVehicle, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

VehicleServiceClient.prototype.deleteVehicle = function deleteVehicle(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(VehicleService.deleteVehicle, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.VehicleServiceClient = VehicleServiceClient;

