// package: gridos.proto.operator
// file: operator/utils.proto

var operator_utils_pb = require("../operator/utils_pb");
var models_general_pb = require("../models/general_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var UtilsService = (function () {
  function UtilsService() {}
  UtilsService.serviceName = "gridos.proto.operator.UtilsService";
  return UtilsService;
}());

UtilsService.AddressComplete = {
  methodName: "AddressComplete",
  service: UtilsService,
  requestStream: false,
  responseStream: true,
  requestType: operator_utils_pb.AddressCompleteRequest,
  responseType: models_general_pb.Address
};

UtilsService.RadiusAddressComplete = {
  methodName: "RadiusAddressComplete",
  service: UtilsService,
  requestStream: false,
  responseStream: true,
  requestType: operator_utils_pb.AddressCompleteRequest,
  responseType: models_general_pb.Address
};

exports.UtilsService = UtilsService;

function UtilsServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

UtilsServiceClient.prototype.addressComplete = function addressComplete(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UtilsService.AddressComplete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

UtilsServiceClient.prototype.radiusAddressComplete = function radiusAddressComplete(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(UtilsService.RadiusAddressComplete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.UtilsServiceClient = UtilsServiceClient;

