import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { DeleteForever } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export default function ConfirmedDeletionButton({
  message, onConfirm, disabled, title, icon,
}) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleConfirm = (e) => {
    handleClose(e);
    onConfirm();
  };

  return (
    <div>
      <IconButton color="error" title={title} onClick={(e) => handleOpen(e)} disabled={disabled}>
        { icon }
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Sicher?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="large" onClick={handleConfirm} autoFocus>Bestätigen</Button>
          <Button variant="outlined" size="large" onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmedDeletionButton.defaultProps = {
  disabled: false,
  title: 'Löschen',
  icon: <DeleteForever />,
};

ConfirmedDeletionButton.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.element,
};
