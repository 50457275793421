import React, {
  createContext,
  useState,
  useMemo,
  useContext, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const ErrorContext = createContext();

/**
 * Returns helper functions to open and close the side drawer
 * @returns {openSideDrawer, closeSideDrawer}
 */
export const useError = () => useContext(ErrorContext);

/**
 * Provides a wrapper to display a side drawer
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export function ErrorProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const showError = useCallback((newMessage) => {
    setOpen(true);
    setMessage(newMessage);
  }, [setOpen, setMessage]);
  const showGenericError = useCallback((e = undefined) => {
    if (e) {
      // Optionally log error for debugging
      console.error(e);
    }
    if (e?.details) {
      showError(`Fehler aufgetreten: ${e.details}`);
    } else {
      showError('Bei dieser Aktion ist ein Fehler aufgetreten');
    }
  }, [showError]);
  const closeError = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const providerFunctions = useMemo(() => ({
    showError,
    showGenericError,
    closeError,
  }), [showError, showGenericError, closeError]);

  return (
    <ErrorContext.Provider value={providerFunctions}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closeError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" sx={{ width: '100%', boxShadow: 3 }}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </ErrorContext.Provider>
  );
}

ErrorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
