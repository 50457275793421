// package: gridos.proto.operator
// file: operator/drivers.proto

var operator_drivers_pb = require("../operator/drivers_pb");
var models_general_pb = require("../models/general_pb");
var models_user_pb = require("../models/user_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var DriverService = (function () {
  function DriverService() {}
  DriverService.serviceName = "gridos.proto.operator.DriverService";
  return DriverService;
}());

DriverService.ListDrivers = {
  methodName: "ListDrivers",
  service: DriverService,
  requestStream: false,
  responseStream: true,
  requestType: models_general_pb.Empty,
  responseType: models_user_pb.Driver
};

DriverService.CreateDriver = {
  methodName: "CreateDriver",
  service: DriverService,
  requestStream: false,
  responseStream: true,
  requestType: operator_drivers_pb.CreateDriverRequest,
  responseType: models_user_pb.Driver
};

DriverService.UpdateDriver = {
  methodName: "UpdateDriver",
  service: DriverService,
  requestStream: false,
  responseStream: true,
  requestType: operator_drivers_pb.UpdateDriverRequest,
  responseType: models_user_pb.Driver
};

DriverService.DeleteDriver = {
  methodName: "DeleteDriver",
  service: DriverService,
  requestStream: false,
  responseStream: true,
  requestType: operator_drivers_pb.DeleteDriverRequest,
  responseType: models_user_pb.Driver
};

DriverService.RenewSignUpCode = {
  methodName: "RenewSignUpCode",
  service: DriverService,
  requestStream: false,
  responseStream: true,
  requestType: operator_drivers_pb.RenewDriverSignUpCodeRequest,
  responseType: models_user_pb.Driver
};

exports.DriverService = DriverService;

function DriverServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DriverServiceClient.prototype.listDrivers = function listDrivers(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(DriverService.ListDrivers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

DriverServiceClient.prototype.createDriver = function createDriver(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(DriverService.CreateDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

DriverServiceClient.prototype.updateDriver = function updateDriver(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(DriverService.UpdateDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

DriverServiceClient.prototype.deleteDriver = function deleteDriver(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(DriverService.DeleteDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

DriverServiceClient.prototype.renewSignUpCode = function renewSignUpCode(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(DriverService.RenewSignUpCode, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.DriverServiceClient = DriverServiceClient;

