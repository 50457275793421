import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import ActivityStatus from './ActivityStatus';
import { driverPropType } from './DriverForm';
import SignUpCodeDisplay from './SignUpCodeDisplay';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'E-Mail',
    flex: 1,
  },
  {
    field: 'signupcode',
    headerName: 'Signup-Code',
    flex: 1,
    renderCell: ({ value }) => (value && (
      <>
        <SignUpCodeDisplay code={value} />
        { value.substring(0, 1) + '*'.repeat(value.length - 1) }
      </>
    )),
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: [
      { value: true, label: 'Aktiv' },
      { value: false, label: 'Inaktiv' },
    ],
    renderCell: ({ value }) => (
      <ActivityStatus active={value} />
    ),
    flex: 1,
  },
];

export default function DriverTable({ drivers, onRowClick }) {
  return (
    <DataGrid
      style={{ height: '68vh', minHeight: 400 }}
      rows={drivers}
      columns={columns}
      onRowClick={(e) => onRowClick(e.row)}
    />
  );
}

DriverTable.propTypes = {
  drivers: PropTypes.arrayOf(driverPropType).isRequired,
  onRowClick: PropTypes.func.isRequired,
};
