import React from 'react';
import PropTypes from 'prop-types';

import { DataGrid } from '@mui/x-data-grid';
import { t } from 'i18next';

import { addressToString } from '../api/parcelAdapter';
import { enumIndexToString } from './EnumMenu';
import { Handover } from '../proto/models/parcel_pb';
import { nowString, dateStringOf, timeStringOf } from '../utils/datetime';
import { timeWindowPropType, timeWindowToString } from './TimeWindowForm';

export const historyRow = (tour, parcel) => {
  const fallbackDate = tour?.starteddatetime || nowString();
  return {
    id: parcel.id,
    date: (parcel.handover.performedtime)
      ? parcel.handover.performedtime
      : fallbackDate,
    type: parcel.type,
    name: parcel.address.name,
    address: addressToString(parcel.address),
    status: parcel.status,
    timeWindow: (parcel.timewindow)
      ? parcel.timewindow
      : null,
    customerNumber: (parcel.customernumber)
      ? parcel.customernumber
      : null,
    barcode: (parcel.barcode)
      ? parcel.barcode
      : null,
    notes: (parcel.notes)
      ? parcel.notes
      : null,
    handoverType: parcel.handover.expectedtype,
    handoverTime: (parcel.handover.performedtime)
      ? parcel.handover.performedtime
      : null,
  };
};

export const columns = [
  {
    field: 'date',
    headerName: 'Datum',
    valueGetter: ({ value }) => {
      const date = dateStringOf(value);
      const today = dateStringOf(nowString());
      return (date === today) ? 'Heute' : date;
    },
  },
  {
    field: 'type',
    headerName: 'Typ',
    valueGetter: ({ value }) => t(`parcel.type.${value}`),
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    valueGetter: ({ value }) => value,
  },
  {
    field: 'address',
    headerName: 'Adresse',
    flex: 2,
    valueGetter: ({ value }) => value,
  },
  {
    field: 'status',
    headerName: 'Status',
    valueGetter: ({ value }) => t(`parcel.status.${value}`),
  },
  {
    field: 'timeWindow',
    headerName: 'Zeitfenster',
    flex: 1,
    valueFormatter: ({ value }) => ((value) ? timeWindowToString(value) : ''),
  },
  {
    field: 'customerNumber',
    headerName: 'Kundennummer',
    valueGetter: ({ value }) => value ?? '',
  },
  {
    field: 'barcode',
    headerName: 'Barcode',
    valueGetter: ({ value }) => value ?? '',
  },
  {
    field: 'notes',
    headerName: 'Notizen',
    valueGetter: ({ value }) => value ?? '',
  },
  {
    field: 'handoverType',
    headerName: 'PoD-Typ',
    valueGetter: ({ value }) => t(`handover.type.${enumIndexToString(Handover.Type, value)}`),
  },
  {
    field: 'handoverTime',
    headerName: 'PoD-Zeit',
    valueGetter: ({ value }) => ((value) ? `${timeStringOf(value)} Uhr` : ''),
  },
];

export const historyRowPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  timeWindow: timeWindowPropType,
  customerNumber: PropTypes.string,
  barcode: PropTypes.string,
  notes: PropTypes.string,
  handoverType: PropTypes.number.isRequired,
  handoverTime: PropTypes.string,
});

export default function ParcelHistoryTable({ parcels, onRowClick }) {
  return (
    <DataGrid
      style={{ height: '68vh', minHeight: 400 }}
      rows={parcels}
      columns={columns}
      onRowClick={(e) => onRowClick(e.row)}
    />
  );
}

ParcelHistoryTable.defaultProps = {
  onRowClick: () => {},
};

ParcelHistoryTable.propTypes = {
  parcels: PropTypes.arrayOf(historyRowPropType).isRequired,
  onRowClick: PropTypes.func,
};
