export default function parseCSV(str, delimiter = (/[;,\t]/)) {
  let firstLineBreak = str.indexOf('\r\n');
  if (firstLineBreak === -1) firstLineBreak = str.indexOf('\n');
  const headers = str.slice(0, firstLineBreak)
    .split(delimiter);
  const rows = str.slice(firstLineBreak + 1)
    .split('\n')
    .filter((line) => line.length > 0);

  return rows.map((row, rowIndex) => {
    const values = row.split(delimiter);
    const el = {
      id: `row-${rowIndex}`,
    };
    headers.forEach((header, columnIndex) => {
      let value = values[columnIndex];
      if (header === 'country' && value === '') { // use default value if `country` is not set
        value = 'DE';
      }
      el[header] = value;
    });
    return el;
  });
}
