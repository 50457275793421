// package: gridos.proto.operator
// file: operator/tenant.proto

var operator_tenant_pb = require("../operator/tenant_pb");
var models_general_pb = require("../models/general_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TenantService = (function () {
  function TenantService() {}
  TenantService.serviceName = "gridos.proto.operator.TenantService";
  return TenantService;
}());

TenantService.GetTenantSettings = {
  methodName: "GetTenantSettings",
  service: TenantService,
  requestStream: false,
  responseStream: false,
  requestType: models_general_pb.Empty,
  responseType: operator_tenant_pb.TenantSettings
};

exports.TenantService = TenantService;

function TenantServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TenantServiceClient.prototype.getTenantSettings = function getTenantSettings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TenantService.GetTenantSettings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TenantServiceClient = TenantServiceClient;

