const config = {};

config.APP_VERSION_CODE = process.env.REACT_APP_VERSION_CODE;
config.APP_VERSION_HASH = process.env.REACT_APP_VERSION_HASH;
config.APP_VERSION_NAME = process.env.REACT_APP_VERSION_NAME;

// Custom environment variables have to be prefixed with "REACT_APP_"
config.API_URL = process.env.REACT_APP_API_URL || 'https://gridos.strazoon.io/api';

config.API_VERSION = '1.3';

// Note: This is configured in Dockerfile, otherwise by default set to ""
config.PUBLIC_URL = process.env.PUBLIC_URL; // e.g. "https://gridos.strazoon.io/operator"

if (config.PUBLIC_URL === '') {
  config.BASENAME = '/';
} else {
  config.BASENAME = new URL(config.PUBLIC_URL).pathname; // e.g. "/operator"
}
config.BASENAME_NO_TRAILING = config.BASENAME.endsWith('/')
  ? config.BASENAME.substring(0, config.BASENAME.length - 1)
  : config.BASENAME;

// Refers to "Cargobike Delivery Operator (Dev)" by default
config.FIREBASE_ANALYTICS_APPID = process.env.REACT_APP_FIREBASE_ANALYTICS_APPID || '1:320639462274:web:d4a8ebba018c2247b8fd9b';

// eslint-disable-next-line no-console
console.info('Config:', config);

export default config;
