import React, {
  createContext,
  useState,
  useMemo,
  useContext,
} from 'react';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

export const SideDrawerContext = createContext();

/**
 * Returns helper functions to open and close the side drawer
 * @returns {openSideDrawer, closeSideDrawer}
 */
export const useSideDrawer = () => useContext(SideDrawerContext);

/**
 * Provides a wrapper to display a side drawer
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export function SideDrawerProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState();
  const openSideDrawer = (newContent) => {
    setOpen(true);
    setContent(newContent);
  };
  const closeSideDrawer = () => {
    setOpen(false);
  };
  const providerFunctions = useMemo(() => ({ openSideDrawer, closeSideDrawer }), []);

  return (
    <SideDrawerContext.Provider value={providerFunctions}>
      <Drawer
        anchor="right"
        open={open}
        onClose={closeSideDrawer}
        PaperProps={{
          sx: { width: '450px' },
        }}
      >
        <Stack sx={{ padding: '20px' }}>
          {content}
        </Stack>
      </Drawer>
      {children}
    </SideDrawerContext.Provider>
  );
}

SideDrawerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
