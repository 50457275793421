import { arrayToObjects, asGenerator, iteratorToArray } from './helpers';
import { CreateParcelRequest, UpdateParcelRequest, DeleteParcelRequest } from '../proto/operator/parcels_pb';
import { Empty, Address, Location } from '../proto/models/general_pb';
import { createServiceClient } from './createServiceClient';
import { ParcelService } from '../proto/operator/parcels_pb_service';
import { Parcel, TimeWindow } from '../proto/models/parcel_pb';
import { enumIndexToString, enumStringToIndex } from '../components/EnumMenu';

const client = createServiceClient(ParcelService);

export const mapToProtoAddress = (address) => {
  const protoAddress = new Address();
  protoAddress.setName(address.name);
  protoAddress.setStreet(address.street);
  protoAddress.setHousenumber(address.houseNumber);
  protoAddress.setZipcode(address.zipCode);
  protoAddress.setCity(address.city);
  protoAddress.setCountry(address.country);
  const location = new Location();
  location.setLongitude(address.longitude);
  location.setLatitude(address.latitude);
  protoAddress.setLocation(location);
  return protoAddress;
};

export const addressToString = (address) => {
  if (address && address.street && address.zipCode && address.city) {
    return `${address.street}${address.houseNumber ? ` ${address.houseNumber}` : ''}, ${address.zipCode} ${address.city}`;
  }
  return '';
};

const mapToProtoTimeWindow = (tw) => {
  if (!tw || (!tw.start && !tw.end)) return null;
  const timeWindow = new TimeWindow();
  timeWindow.setStart(tw.start);
  timeWindow.setEnd(tw.end);
  return timeWindow;
};

export const mapAddressFromProto = (address) => ({
  ...address,
  houseNumber: address.housenumber,
  zipCode: address.zipcode,
  latitude: address.location.latitude,
  longitude: address.location.longitude,
});

export const mapParcelFromProto = (parcel) => ({
  ...parcel,
  address: mapAddressFromProto(parcel.address),
  status: enumIndexToString(Parcel.Status, parcel.status),
  type: enumIndexToString(Parcel.Type, parcel.type),
  companionId: parcel.companionid,
  timeWindow: parcel.timewindow,
  customerNumber: parcel.customernumber,
  barcode: parcel.barcode,
  notes: parcel.notes,
});

/**
 * Fetch list of parcels
 * @returns {Promise<*[]>}
 */
export async function listParcels() {
  const stream = client.listParcels(new Empty());
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapParcelFromProto);
}

/**
 * Update a parcel
 *
 * @param parcel object describing the new attribute values of the designated parcel
 * @returns {Promise<*[]>}
 */
export async function updateParcel(parcel) {
  const address = mapToProtoAddress(parcel.address);
  const timeWindow = mapToProtoTimeWindow(parcel.timeWindow);
  const updateRequest = new UpdateParcelRequest();
  updateRequest.setId(parcel.id);
  updateRequest.setStatus(enumStringToIndex(Parcel.Status, parcel.status));
  updateRequest.setType(enumStringToIndex(Parcel.Type, parcel.type));
  updateRequest.setCompanionid(parcel.companionId);
  updateRequest.setAddress(address);
  updateRequest.setTimewindow(timeWindow);
  updateRequest.setCustomernumber(parcel.customerNumber);
  updateRequest.setBarcode(parcel.barcode);
  updateRequest.setNotes(parcel.notes);
  const stream = client.updateParcel(updateRequest);
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapParcelFromProto);
}

/**
 * Create a parcel
 *
 * @param parcel object describing the attribute values of the new parcel
 * @returns {Promise<*[]>}
 */
export async function createParcel(parcel) {
  const address = mapToProtoAddress(parcel.address);
  const timeWindow = mapToProtoTimeWindow(parcel.timeWindow);
  const createRequest = new CreateParcelRequest();
  createRequest.setStatus(enumStringToIndex(Parcel.Status, parcel.status));
  createRequest.setType(enumStringToIndex(Parcel.Type, parcel.type));
  createRequest.setCompanionid(parcel.companionId);
  createRequest.setAddress(address);
  createRequest.setTimewindow(timeWindow);
  createRequest.setCustomernumber(parcel.customerNumber);
  createRequest.setBarcode(parcel.barcode);
  createRequest.setNotes(parcel.notes);
  const stream = client.createParcel(createRequest);
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapParcelFromProto);
}

/**
 * Create a batch of parcels
 *
 * @param parcels array of objects describing the attribute values of the new parcels
 * @returns {Promise<*[]>}
 */
export function createParcelBatch(parcels) {
  const protoParcels = parcels.map((parcel) => {
    const createRequest = new CreateParcelRequest();
    createRequest.setStatus(3); // PLANNING
    createRequest.setType(1); // DELIVERY

    const address = new Address();
    address.setName(parcel.name);
    address.setStreet(parcel.street);
    address.setHousenumber(parcel.houseNumber);
    address.setZipcode(parcel.zipCode);
    address.setCity(parcel.city);
    address.setCountry(parcel.country);
    const location = new Location();
    location.setLongitude(parcel.longitude);
    location.setLatitude(parcel.latitude);
    address.setLocation(location);
    createRequest.setAddress(address);

    if (parcel.start.trim() !== '' && parcel.end.trim() !== '') {
      const timeWindow = new TimeWindow();
      timeWindow.setStart(parcel.start);
      timeWindow.setEnd(parcel.end);
      createRequest.setTimewindow(timeWindow);
    }

    createRequest.setCustomernumber(parcel.customerNumber);
    createRequest.setBarcode(parcel.barcode);
    createRequest.setNotes(parcel.notes);

    return createRequest;
  });
  return client.createBatch(asGenerator(protoParcels));
}

/**
 * Delete a parcel
 *
 * @param parcelId of the parcel to delete
 * @returns {Promise<*[]>}
 */
export async function deleteParcelById(parcelId) {
  const deleteRequest = new DeleteParcelRequest();
  deleteRequest.setId(parcelId);
  const stream = client.deleteParcel(deleteRequest);
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapParcelFromProto);
}
