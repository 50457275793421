import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { useAsync } from 'react-async';
import { useError } from './ErrorContext';
import { addressToString, listParcels } from '../api/parcelAdapter';

export default function ParcelPickerInput({
  label, value, blacklistedIds, onChange,
}) {
  const { showGenericError } = useError();

  const [parcels, setParcels] = useState([]);
  useAsync(listParcels, {
    onResolve: (newParcels) => {
      const filteredParcels = newParcels.filter(({ id }) => !blacklistedIds.includes(id));
      setParcels(filteredParcels);
    },
    onReject: showGenericError,
  });

  const onInternalChange = (e, option) => {
    const newValue = option ? option.id : null;
    onChange(newValue);
  };

  const [internalValue, setInternalValue] = useState(null);
  useEffect(() => {
    if (parcels.length === 0) return;
    const newInternalValue = parcels.find(({ id }) => (id === value));
    setInternalValue(newInternalValue);
  }, [value, parcels]);

  return (
    <Autocomplete
      options={parcels}
      getOptionLabel={(option) => (`${option.address.name}, ${addressToString(option.address)}`)}
      onChange={onInternalChange}
      isOptionEqualToValue={(option, val) => option.id === val?.id}
      value={internalValue || null}
      clearText="Verknüpfung löschen"
      openText="Auswählen"
      selectOnFocus
      fullWidth
      loading={parcels.length === 0}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          placeholder="Depot"
          label={label}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}

ParcelPickerInput.defaultProps = {
  blacklistedIds: [],
  value: null,
};

ParcelPickerInput.propTypes = {
  blacklistedIds: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
