import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { IconButton, TextField } from '@mui/material';
import QrCode2Icon from '@mui/icons-material/QrCode2';

export default function SignUpCodeDisplay({ code }) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton title="Löschen" onClick={(e) => { e.stopPropagation(); setOpen(true); }}>
        <QrCode2Icon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Sign-Up Code
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img
              alt="QR"
              src={`https://barcode.tec-it.com/barcode.ashx?data=${code}&code=QRCode&dpi=192`}
              style={{
                width: '100%',
              }}
            />
          </DialogContentText>
          <TextField
            defaultValue={code}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            sx={{
              input: {
                textAlign: 'center',
                fontSize: 'larger',
              },
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

SignUpCodeDisplay.propTypes = {
  code: PropTypes.string.isRequired,
};
