import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  MapContainer, Marker, Polyline, Popup, TileLayer,
} from 'react-leaflet';
import { useAsync } from 'react-async';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useError } from './ErrorContext';
import { getTenantSettings } from '../api/tenantAdapter';
import { hashToHslColor } from '../utils/color';
import { addressPropType } from './AddressForm';
import shadowIcon from '../images/shadowIcon.png';

const addressToCoordinates = (address) => ([address.location.latitude, address.location.longitude]);

export default function TourOverviewMap({ data }) {
  const { showGenericError } = useError();

  const [depot, setDepot] = useState(null);
  useAsync(getTenantSettings, {
    onResolve: (settings) => setDepot((settings.depotsList) ? settings.depotsList[0] : null),
    onReject: showGenericError,
  });

  return (
    depot && (
    <MapContainer className="tour-overview-map" center={addressToCoordinates(depot.address)} zoom={13}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <OverviewMapMarker
        key={depot.id}
        address={depot.address}
        fillColor={hashToHslColor(depot.id, '65%')}
        strokeColor={hashToHslColor(depot.id, '50%')}
      />
      {data && data.toursList.map((row) => (
        <TourOverviewMapRow
          key={row.id}
          row={row}
        />
      ))}
      {data && data.backlogList.map((parcel) => (
        <OverviewMapMarker
          key={parcel.id}
          address={parcel.address}
          fillColor="hsla(0, 0%, 65%, 0.8)"
          strokeColor="hsla(0, 0%, 50%, 0.8)"
          scale={0.8}
        />
      ))}
    </MapContainer>
    )
  );
}

TourOverviewMap.defaultProps = {
  data: null,
};

TourOverviewMap.propTypes = {
  data: PropTypes.any,
};

function TourOverviewMapRow({ row }) {
  const fillColor = hashToHslColor(row.id, '50%');
  const strokeColor = hashToHslColor(row.id, '35%');
  const positions = row.parcelsList.map((parcel) => addressToCoordinates(parcel.address));
  return (
    <>
      <Polyline
        pathOptions={{ color: fillColor }}
        positions={positions}
      />
      {row.parcelsList.map((parcel) => (
        <OverviewMapMarker
          key={parcel.id}
          address={parcel.address}
          fillColor={fillColor}
          strokeColor={strokeColor}
          scale={0.8}
        />
      ))}
    </>
  );
}

TourOverviewMapRow.propTypes = {
  row: PropTypes.any.isRequired,
};

function OverviewMapMarker({
  address, fillColor, strokeColor, scale,
}) {
  const size = 48;
  return (
    <Marker
      position={addressToCoordinates(address)}
      icon={divIcon({
        html: renderToStaticMarkup(
          <LocationOnIcon
            style={{
              fill: fillColor,
              stroke: strokeColor,
              strokeWidth: size / 100,
              fontSize: size * scale,
            }}
          />,
        ),
        className: '', // must be overridden to get rid of white square
        iconSize: [size * scale, size * scale],
        iconAnchor: [(size / 2) * scale, (size - size / 16) * scale],
        popupAnchor: [0, -size * scale],
        shadowUrl: shadowIcon,
        shadowSize: [size * scale, size * scale],
      })}
    >
      <Popup>
        {address.name}
        <br />
        {address.street}
        {' '}
        {address.housenumber}
        <br />
        {address.zipcode}
        {' '}
        {address.city}
      </Popup>
    </Marker>
  );
}

OverviewMapMarker.defaultProps = {
  scale: 1.0,
};

OverviewMapMarker.propTypes = {
  address: addressPropType.isRequired,
  fillColor: PropTypes.string.isRequired,
  strokeColor: PropTypes.string.isRequired,
  scale: PropTypes.number,
};
