import { createServiceClient } from './createServiceClient';
import { mapAddressFromProto } from './parcelAdapter';
import { Empty } from '../proto/models/general_pb';
import { TenantService } from '../proto/operator/tenant_pb_service';

const client = createServiceClient(TenantService);

export const mapDepotFromProto = (depot) => ({
  ...depot,
  address: mapAddressFromProto(depot.address),
});

export const mapTenantSettingsFromProto = (tenant) => ({
  ...tenant,
  depots: tenant.depotsList.map(mapDepotFromProto),
});

export const getTenantSettings = async () => {
  const results = await client.getTenantSettings(new Empty());
  return mapTenantSettingsFromProto(results.toObject());
};
