import React, {
  createContext,
  useMemo,
  useContext,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { login as loginApi, verify as verifyApi } from '../api/loginAdapter';
import {
  provideCurrentToken, provideCurrentUser, removeAuthInfo, storeAuthInfo,
} from '../api/helpers';
import config from '../config';

export const AuthContext = createContext(null);
export const useAuth = () => useContext(AuthContext);

function removePrefix(str, prefix) {
  const hasPrefix = str.indexOf(prefix) === 0;
  return hasPrefix ? str.substring(prefix.length) : str;
}

/**
 * Provides a wrapper to handle auth state
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export function AuthProvider({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(provideCurrentToken());
  const [user, setUser] = useState(provideCurrentUser());

  const login = useCallback(async (tenant, email, password) => {
    const response = await loginApi(tenant, email, password);
    storeAuthInfo(response);
    setToken(response.apitoken);
    setUser(response.maintainer || response.operator);
    const origin = location.state && location.state.from && location.state.from.pathname;
    if (origin) {
      navigate(removePrefix(origin, config.BASENAME_NO_TRAILING));
    } else {
      navigate('/');
    }
  }, [setToken, setUser, location, navigate]);

  const verify = useCallback(async (verificationCode) => {
    const response = await verifyApi(verificationCode);
    storeAuthInfo(response);
    setToken(response.apitoken);
    setUser(response.maintainer || response.operator);
    navigate('/');
  }, [setToken, setUser, navigate]);

  const logout = useCallback(() => {
    removeAuthInfo();
    setToken(null);
    setUser(null);
    navigate('/login');
  }, [setToken, setUser, navigate]);

  const authState = useMemo(() => ({
    token,
    user,
    isLoggedIn: () => !!token,
    login,
    logout,
    verify,
  }), [token, user, login, logout, verify]);

  return (
    <AuthContext.Provider value={authState}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
