import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './de.json';

export default function initI18n() {
  i18n
    .use(initReactI18next)
    .init({
      resources: {
        de: {
          translation: de,
        },
      },
      lng: 'de', // if you're using a language detector, do not define the lng option
      fallbackLng: 'de',
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });
}
