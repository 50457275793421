import React from 'react';
import { Typography } from '@mui/material';
import crash from '../images/crash.gif';

export default function NotFoundPage() {
  return (
    <section>
      <Typography variant="h2" className="no-margin">Hoppla!</Typography>
      <p>Diese Seite ist noch in Arbeit</p>
      <img src={crash} alt="Cyclist crashing into a car" />
    </section>
  );
}
