import { createTheme } from '@mui/material/styles';
import { deDE } from '@mui/x-data-grid';

export default createTheme(
  {
    shape: {
      borderRadius: 6,
    },
    palette: {
      primary: {
        main: '#04f368',
      },
      secondary: {
        main: '#707070',
      },
      green: {
        main: '#04f368',
      },
      yellow: {
        main: '#ffff00',
      },
      red: {
        main: '#fc1066',
      },
      black: {
        main: '#000000',
      },
    },
    typography: {
      fontFamily: 'Heebo, sans-serif',
      fontSize: 12,
      h1: {
        fontSize: '3.5rem',
        fontWeight: 300,
        color: 'black',
      },
      h2: {
        fontSize: '2.25rem',
        fontWeight: 300,
        color: 'black',
      },
      h3: {
        fontSize: '1.5rem',
        fontWeight: 500,
        color: 'black',
      },
      h4: {
        fontSize: '1.25rem',
        fontWeight: 500,
        color: 'black',
      },
      h5: {
        fontSize: '1.125rem',
        fontWeight: 500,
        color: 'black',
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 600,
        color: 'black',
      },
      body1: {
        fontSize: '0.75rem',
        fontWeight: 500,
      },
      button: {
        fontSize: '0.75rem',
        textTransform: 'none',
        fontWeight: 500,
      },
    },
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 3,
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            height: '.5rem',
            borderRadius: 6,
            boxShadow: '0 1.5px 3px 0 rgb(0 0 0 / 16%)',
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: '#f5f5f5',
            fontWeight: 700,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            '&:last': {
              borderBottom: 'none',
            },
          },
        },
      },
    },
  },
  deDE, // Show data grid in German
);
