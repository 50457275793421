import { Empty } from '../proto/models/general_pb';
import { createServiceClient } from './createServiceClient';
import { TourService } from '../proto/operator/tours_pb_service';
import { enumIndexToString } from '../components/EnumMenu';
import { Tour } from '../proto/models/tour_pb';
import { mapParcelFromProto } from './parcelAdapter';
import {
  ApproveTourRequest,
  DeleteTourRequest,
  MoveParcelRequest,
  UpdateTourRequest,
  CancelTourRequest,
} from '../proto/operator/tours_pb';
import { arrayToObjects, iteratorToArray } from './helpers';

const client = createServiceClient(TourService);

const mapTourFromProto = (tour) => ({
  ...tour,
  parcelsList: tour.parcelsList.map(mapParcelFromProto),
  status: enumIndexToString(Tour.Status, tour.status),
});

const mapTourListReplyFromProto = (reply) => ({
  ...reply,
  // Reverse, because we want the newest on the top of the list
  toursList: reply.toursList.map(mapTourFromProto).reverse(),
  backlogList: reply.backlogList.map(mapParcelFromProto),
});

/**
 * Fetch list of current assignments
 * @returns {Promise<*[]>}
 */
export async function listCurrentTours() {
  const response = await client.listCurrentTours(new Empty());
  return mapTourListReplyFromProto(response.toObject());
}

/**
 * Fetch list of all assignments
 * @returns {Promise<*[]>}
 */
export async function listAllTours() {
  const stream = await client.listAllTours(new Empty());
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapTourFromProto);
}

/**
 * Create tour
 * @returns {Promise<*[]>}
 */
export async function createTour() {
  const response = await client.createTour(new Empty());
  return mapTourListReplyFromProto(response.toObject());
}

/**
 * Create tour
 * @returns {Promise<*[]>}
 */
export async function updateTour(tourID, driverID, vehicleID) {
  const request = new UpdateTourRequest();
  request.setTourid(tourID);
  request.setDriverid(driverID);
  request.setVehicleid(vehicleID);
  const response = await client.updateTour(request);
  return mapTourListReplyFromProto(response.toObject());
}

/**
 * Approve tour
 * @returns {Promise<*[]>}
 */
export async function approveTour(tourID) {
  const request = new ApproveTourRequest();
  request.setTourid(tourID);
  const response = await client.approveTour(request);
  return mapTourListReplyFromProto(response.toObject());
}

/**
 * Cancel tour
 * @returns {Promise<*[]>}
 */
export async function cancelTour(tourID) {
  const request = new CancelTourRequest();
  request.setTourid(tourID);
  const response = await client.cancelTour(request);
  return mapTourListReplyFromProto(response.toObject());
}

/**
 * Delete tour
 * @returns {Promise<*[]>}
 */
export async function deleteTour(tourID) {
  const request = new DeleteTourRequest();
  request.setTourid(tourID);
  const response = await client.deleteTour(request);
  return mapTourListReplyFromProto(response.toObject());
}

/**
 * Move parcel in tour list / backlog
 * @returns {Promise<*[]>}
 */
export async function moveParcel(parcelId, tourID, newIndex) {
  const request = new MoveParcelRequest();
  request.setParcelid(parcelId);
  request.setTourid(tourID);
  request.setNewindex(newIndex);
  const response = await client.moveParcel(request);
  return mapTourListReplyFromProto(response.toObject());
}

/**
 * Generate tours automatically from backlog
 * @returns {Promise<*[]>}
 */
export async function generateOptimizedTours() {
  const response = await client.generateOptimizedTours(new Empty());
  return mapTourListReplyFromProto(response.toObject());
}
