import React, { useState } from 'react';
import { useAsync } from 'react-async';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Stack, Typography } from '@mui/material';
import { listCurrentTours, createTour } from '../api/tourAdapter';

import TourOverviewMap from '../components/TourOverviewMap';
import TourOverviewTable from '../components/TourOverviewTable';
import { useError } from '../components/ErrorContext';

export default function IndexPage() {
  const { showGenericError } = useError();

  const [data, setData] = useState(null);
  useAsync(listCurrentTours, {
    onResolve: (newData) => setData(newData),
    onReject: showGenericError,
  });

  const onCreateTourButtonClicked = async () => {
    try {
      const newData = await createTour();
      setData(newData);
    } catch (e) {
      showGenericError(e);
    }
  };

  const onUpdate = (updatedTours) => {
    setData(updatedTours);
  };

  return (
    <>
      <section>
        <TourOverviewMap data={data} />
      </section>
      <section className="section--small-padding">
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="h3" className="no-margin" style={{ marginLeft: 16 }}>Tourenplanung</Typography>
          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={onCreateTourButtonClicked}
          >
            Neue Tour hinzufügen
          </Button>
        </Stack>
      </section>
      <section className="section--small-padding">
        <TourOverviewTable data={data} onUpdate={onUpdate} />
      </section>
    </>
  );
}
