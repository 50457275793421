// source: operator/tours.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var models_general_pb = require('../models/general_pb.js');
goog.object.extend(proto, models_general_pb);
var models_parcel_pb = require('../models/parcel_pb.js');
goog.object.extend(proto, models_parcel_pb);
var models_tour_pb = require('../models/tour_pb.js');
goog.object.extend(proto, models_tour_pb);
goog.exportSymbol('proto.gridos.proto.operator.ApproveTourRequest', null, global);
goog.exportSymbol('proto.gridos.proto.operator.CancelTourRequest', null, global);
goog.exportSymbol('proto.gridos.proto.operator.DeleteTourRequest', null, global);
goog.exportSymbol('proto.gridos.proto.operator.MoveParcelRequest', null, global);
goog.exportSymbol('proto.gridos.proto.operator.TourListReply', null, global);
goog.exportSymbol('proto.gridos.proto.operator.UpdateTourRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.operator.UpdateTourRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.operator.UpdateTourRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.operator.UpdateTourRequest.displayName = 'proto.gridos.proto.operator.UpdateTourRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.operator.MoveParcelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.operator.MoveParcelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.operator.MoveParcelRequest.displayName = 'proto.gridos.proto.operator.MoveParcelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.operator.ApproveTourRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.operator.ApproveTourRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.operator.ApproveTourRequest.displayName = 'proto.gridos.proto.operator.ApproveTourRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.operator.CancelTourRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.operator.CancelTourRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.operator.CancelTourRequest.displayName = 'proto.gridos.proto.operator.CancelTourRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.operator.DeleteTourRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gridos.proto.operator.DeleteTourRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.operator.DeleteTourRequest.displayName = 'proto.gridos.proto.operator.DeleteTourRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gridos.proto.operator.TourListReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.gridos.proto.operator.TourListReply.repeatedFields_, null);
};
goog.inherits(proto.gridos.proto.operator.TourListReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gridos.proto.operator.TourListReply.displayName = 'proto.gridos.proto.operator.TourListReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.operator.UpdateTourRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.operator.UpdateTourRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.operator.UpdateTourRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.UpdateTourRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tourid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    driverid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.operator.UpdateTourRequest}
 */
proto.gridos.proto.operator.UpdateTourRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.operator.UpdateTourRequest;
  return proto.gridos.proto.operator.UpdateTourRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.operator.UpdateTourRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.operator.UpdateTourRequest}
 */
proto.gridos.proto.operator.UpdateTourRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTourid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.operator.UpdateTourRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.operator.UpdateTourRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.operator.UpdateTourRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.UpdateTourRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTourid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDriverid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string tourID = 1;
 * @return {string}
 */
proto.gridos.proto.operator.UpdateTourRequest.prototype.getTourid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.UpdateTourRequest} returns this
 */
proto.gridos.proto.operator.UpdateTourRequest.prototype.setTourid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string driverID = 2;
 * @return {string}
 */
proto.gridos.proto.operator.UpdateTourRequest.prototype.getDriverid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.UpdateTourRequest} returns this
 */
proto.gridos.proto.operator.UpdateTourRequest.prototype.setDriverid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicleID = 3;
 * @return {string}
 */
proto.gridos.proto.operator.UpdateTourRequest.prototype.getVehicleid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.UpdateTourRequest} returns this
 */
proto.gridos.proto.operator.UpdateTourRequest.prototype.setVehicleid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.operator.MoveParcelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.operator.MoveParcelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.operator.MoveParcelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.MoveParcelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parcelid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tourid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newindex: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.operator.MoveParcelRequest}
 */
proto.gridos.proto.operator.MoveParcelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.operator.MoveParcelRequest;
  return proto.gridos.proto.operator.MoveParcelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.operator.MoveParcelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.operator.MoveParcelRequest}
 */
proto.gridos.proto.operator.MoveParcelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParcelid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTourid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewindex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.operator.MoveParcelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.operator.MoveParcelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.operator.MoveParcelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.MoveParcelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParcelid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTourid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewindex();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string parcelID = 1;
 * @return {string}
 */
proto.gridos.proto.operator.MoveParcelRequest.prototype.getParcelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.MoveParcelRequest} returns this
 */
proto.gridos.proto.operator.MoveParcelRequest.prototype.setParcelid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tourID = 2;
 * @return {string}
 */
proto.gridos.proto.operator.MoveParcelRequest.prototype.getTourid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.MoveParcelRequest} returns this
 */
proto.gridos.proto.operator.MoveParcelRequest.prototype.setTourid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 newIndex = 3;
 * @return {number}
 */
proto.gridos.proto.operator.MoveParcelRequest.prototype.getNewindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.gridos.proto.operator.MoveParcelRequest} returns this
 */
proto.gridos.proto.operator.MoveParcelRequest.prototype.setNewindex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.operator.ApproveTourRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.operator.ApproveTourRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.operator.ApproveTourRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.ApproveTourRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tourid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.operator.ApproveTourRequest}
 */
proto.gridos.proto.operator.ApproveTourRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.operator.ApproveTourRequest;
  return proto.gridos.proto.operator.ApproveTourRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.operator.ApproveTourRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.operator.ApproveTourRequest}
 */
proto.gridos.proto.operator.ApproveTourRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTourid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.operator.ApproveTourRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.operator.ApproveTourRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.operator.ApproveTourRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.ApproveTourRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTourid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tourID = 1;
 * @return {string}
 */
proto.gridos.proto.operator.ApproveTourRequest.prototype.getTourid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.ApproveTourRequest} returns this
 */
proto.gridos.proto.operator.ApproveTourRequest.prototype.setTourid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.operator.CancelTourRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.operator.CancelTourRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.operator.CancelTourRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.CancelTourRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tourid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.operator.CancelTourRequest}
 */
proto.gridos.proto.operator.CancelTourRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.operator.CancelTourRequest;
  return proto.gridos.proto.operator.CancelTourRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.operator.CancelTourRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.operator.CancelTourRequest}
 */
proto.gridos.proto.operator.CancelTourRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTourid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.operator.CancelTourRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.operator.CancelTourRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.operator.CancelTourRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.CancelTourRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTourid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tourID = 1;
 * @return {string}
 */
proto.gridos.proto.operator.CancelTourRequest.prototype.getTourid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.CancelTourRequest} returns this
 */
proto.gridos.proto.operator.CancelTourRequest.prototype.setTourid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.operator.DeleteTourRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.operator.DeleteTourRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.operator.DeleteTourRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.DeleteTourRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tourid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.operator.DeleteTourRequest}
 */
proto.gridos.proto.operator.DeleteTourRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.operator.DeleteTourRequest;
  return proto.gridos.proto.operator.DeleteTourRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.operator.DeleteTourRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.operator.DeleteTourRequest}
 */
proto.gridos.proto.operator.DeleteTourRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTourid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.operator.DeleteTourRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.operator.DeleteTourRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.operator.DeleteTourRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.DeleteTourRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTourid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tourID = 1;
 * @return {string}
 */
proto.gridos.proto.operator.DeleteTourRequest.prototype.getTourid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gridos.proto.operator.DeleteTourRequest} returns this
 */
proto.gridos.proto.operator.DeleteTourRequest.prototype.setTourid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.gridos.proto.operator.TourListReply.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gridos.proto.operator.TourListReply.prototype.toObject = function(opt_includeInstance) {
  return proto.gridos.proto.operator.TourListReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gridos.proto.operator.TourListReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.TourListReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    toursList: jspb.Message.toObjectList(msg.getToursList(),
    models_tour_pb.Tour.toObject, includeInstance),
    backlogList: jspb.Message.toObjectList(msg.getBacklogList(),
    models_parcel_pb.Parcel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gridos.proto.operator.TourListReply}
 */
proto.gridos.proto.operator.TourListReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gridos.proto.operator.TourListReply;
  return proto.gridos.proto.operator.TourListReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gridos.proto.operator.TourListReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gridos.proto.operator.TourListReply}
 */
proto.gridos.proto.operator.TourListReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_tour_pb.Tour;
      reader.readMessage(value,models_tour_pb.Tour.deserializeBinaryFromReader);
      msg.addTours(value);
      break;
    case 2:
      var value = new models_parcel_pb.Parcel;
      reader.readMessage(value,models_parcel_pb.Parcel.deserializeBinaryFromReader);
      msg.addBacklog(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gridos.proto.operator.TourListReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gridos.proto.operator.TourListReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gridos.proto.operator.TourListReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gridos.proto.operator.TourListReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToursList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_tour_pb.Tour.serializeBinaryToWriter
    );
  }
  f = message.getBacklogList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      models_parcel_pb.Parcel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated gridos.proto.models.Tour tours = 1;
 * @return {!Array<!proto.gridos.proto.models.Tour>}
 */
proto.gridos.proto.operator.TourListReply.prototype.getToursList = function() {
  return /** @type{!Array<!proto.gridos.proto.models.Tour>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_tour_pb.Tour, 1));
};


/**
 * @param {!Array<!proto.gridos.proto.models.Tour>} value
 * @return {!proto.gridos.proto.operator.TourListReply} returns this
*/
proto.gridos.proto.operator.TourListReply.prototype.setToursList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.gridos.proto.models.Tour=} opt_value
 * @param {number=} opt_index
 * @return {!proto.gridos.proto.models.Tour}
 */
proto.gridos.proto.operator.TourListReply.prototype.addTours = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.gridos.proto.models.Tour, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.gridos.proto.operator.TourListReply} returns this
 */
proto.gridos.proto.operator.TourListReply.prototype.clearToursList = function() {
  return this.setToursList([]);
};


/**
 * repeated gridos.proto.models.Parcel backlog = 2;
 * @return {!Array<!proto.gridos.proto.models.Parcel>}
 */
proto.gridos.proto.operator.TourListReply.prototype.getBacklogList = function() {
  return /** @type{!Array<!proto.gridos.proto.models.Parcel>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_parcel_pb.Parcel, 2));
};


/**
 * @param {!Array<!proto.gridos.proto.models.Parcel>} value
 * @return {!proto.gridos.proto.operator.TourListReply} returns this
*/
proto.gridos.proto.operator.TourListReply.prototype.setBacklogList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.gridos.proto.models.Parcel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.gridos.proto.models.Parcel}
 */
proto.gridos.proto.operator.TourListReply.prototype.addBacklog = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.gridos.proto.models.Parcel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.gridos.proto.operator.TourListReply} returns this
 */
proto.gridos.proto.operator.TourListReply.prototype.clearBacklogList = function() {
  return this.setBacklogList([]);
};


goog.object.extend(exports, proto.gridos.proto.operator);
