import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from './AuthContext';

export default function UserInfo() {
  const { user, logout } = useAuth();

  const logoutButton = (
    <Tooltip title="Ausloggen">
      <IconButton onClick={logout} aria-label="Logout" color="error">
        <LogoutIcon />
      </IconButton>
    </Tooltip>
  );
  if (!user) {
    return logoutButton;
  }
  return (
    <div className="header__userinfo">
      <span className="header__username">
        {user.name}
        &nbsp;(
        {user.email}
        )
      </span>
      { logoutButton }
    </div>
  );
}
