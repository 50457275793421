import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch, InputAdornment } from '@mui/material';
import { useSideDrawer } from './SideDrawer';
import ConfirmedDeletionButton from './ConfirmedDeletionButton';
import SaveAbortButtons from './SaveAbortButtons';
import { nameActivityStatus } from './ActivityStatus';
import { createVehicle, deleteVehicleById, updateVehicle } from '../api/vehicleAdapter';
import { useError } from './ErrorContext';

export default function VehicleForm({ vehicle, mode, onUpdate }) {
  const { closeSideDrawer } = useSideDrawer();
  const { showGenericError } = useError();
  const [currentVehicle, setCurrentVehicle] = useState(vehicle);

  const onChange = (changed) => {
    setCurrentVehicle({ ...currentVehicle, ...changed });
  };
  const onDelete = async () => {
    try {
      const updatedDrivers = await deleteVehicleById(currentVehicle.id);
      onUpdate(updatedDrivers);
      closeSideDrawer();
    } catch (e) {
      showGenericError(e);
    }
  };
  const onSave = async () => {
    try {
      const updatedDrivers = (mode === 'create')
        ? await createVehicle(currentVehicle)
        : await updateVehicle(currentVehicle);
      onUpdate(updatedDrivers);
      closeSideDrawer();
    } catch (e) {
      showGenericError(e);
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h4>Fahrzeug</h4>
        {
          mode === 'update'
          && <ConfirmedDeletionButton message={`${currentVehicle.name} löschen?`} onConfirm={onDelete} />
        }
      </Stack>
      <TextField
        label="Name"
        margin="normal"
        value={currentVehicle.name}
        onChange={(e) => onChange({ name: e.target.value })}
      />
      <TextField
        type="number"
        label="Kapazität"
        margin="normal"
        InputProps={{
          inputProps: {
            min: 1,
          },
          endAdornment: (
            <Tooltip
              title="Anzahl der durchschnittlichen Sendungen, die auf dieses Fahrzeug passen"
            >
              <InputAdornment position="end">
                <InfoOutlinedIcon />
              </InputAdornment>
            </Tooltip>
          ),
        }}
        value={currentVehicle.capacity}
        onChange={(e) => onChange({ capacity: e.target.value })}
      />
      <TextField
        multiline
        label="Notizen"
        margin="normal"
        value={currentVehicle.notes}
        onChange={(e) => onChange({ notes: e.target.value })}
      />
      <FormControlLabel
        control={(
          <Switch
            checked={currentVehicle.status}
            onChange={() => onChange({ status: !currentVehicle.status })}
          />
        )}
        label={nameActivityStatus(currentVehicle.status)}
      />
      <SaveAbortButtons onSave={onSave} onAbort={closeSideDrawer} />
    </>
  );
}

export const vehiclePropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  capacity: PropTypes.number,
  status: PropTypes.bool,
  notes: PropTypes.string,
});

VehicleForm.defaultProps = {
  vehicle: {
    id: undefined,
    name: '',
    capacity: 20,
    status: true,
    notes: '',
  },
  onUpdate: () => {},
};

VehicleForm.propTypes = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  vehicle: vehiclePropType,
  onUpdate: PropTypes.func,
};
