import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import {
  FormControlLabel, IconButton, Switch, InputAdornment, OutlinedInput, InputLabel, FormControl,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSideDrawer } from './SideDrawer';
import ConfirmedDeletionButton from './ConfirmedDeletionButton';
import { nameActivityStatus } from './ActivityStatus';
import {
  createDriver, updateDriver, deleteDriverById, renewSignUpCode,
} from '../api/driverAdapter';
import SaveAbortButtons from './SaveAbortButtons';
import { useError } from './ErrorContext';
import SignUpCodeDisplay from './SignUpCodeDisplay';

export default function DriverForm({ driver, mode, onUpdate }) {
  const { closeSideDrawer } = useSideDrawer();
  const { showGenericError } = useError();
  const [currentDriver, setCurrentDriver] = useState(driver);

  const setName = (name) => setCurrentDriver({ ...currentDriver, name });
  const setEmail = (email) => setCurrentDriver({ ...currentDriver, email });
  const setStatus = (status) => setCurrentDriver({ ...currentDriver, status });

  const processDrivers = async (driverProvider) => {
    try {
      const updatedDrivers = await driverProvider();
      onUpdate(updatedDrivers);
      closeSideDrawer();
    } catch (e) {
      showGenericError(e);
    }
  };
  const onDelete = () => processDrivers(() => deleteDriverById(currentDriver.id));
  const onSave = () => (
    (mode === 'create')
      ? processDrivers(() => createDriver(currentDriver))
      : processDrivers(() => updateDriver(currentDriver))
  );
  const onRenew = () => processDrivers(() => renewSignUpCode(currentDriver.id));

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h4>Person</h4>
        {
          mode === 'update'
          && <ConfirmedDeletionButton message={`${currentDriver.name} löschen?`} onConfirm={onDelete} />
        }
      </Stack>
      <TextField
        label="Name"
        margin="normal"
        value={currentDriver.name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        label="E-Mail"
        margin="normal"
        value={currentDriver.email}
        onChange={(e) => setEmail(e.target.value)}
      />
      { (mode !== 'create') && (
        <FormControl variant="outlined" sx={{ mt: 2, mb: 1 }}>
          <InputLabel htmlFor="signup-code-input">Signup-Code</InputLabel>
          <OutlinedInput
            id="signup-code-input"
            label="Signup-Code"
            disabled
            value={currentDriver.signupcode}
            startAdornment={currentDriver.signupcode && (
              <InputAdornment position="start">
                <SignUpCodeDisplay code={currentDriver.signupcode} />
              </InputAdornment>
            )}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="neu generieren"
                  onClick={onRenew}
                  edge="end"
                >
                  <RefreshIcon />
                </IconButton>
              </InputAdornment>
            )}
          />
        </FormControl>
      )}
      <FormControlLabel
        control={(
          <Switch
            checked={currentDriver.status}
            onChange={() => setStatus(!currentDriver.status)}
          />
        )}
        label={nameActivityStatus(currentDriver.status)}
      />
      <SaveAbortButtons onSave={onSave} onAbort={closeSideDrawer} />
    </>
  );
}

export const driverPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  signupcode: PropTypes.string,
  status: PropTypes.bool,
});

DriverForm.defaultProps = {
  driver: {
    id: undefined,
    name: '',
    email: '',
    signupcode: '',
    status: true,
  },
  onUpdate: () => {},
};

DriverForm.propTypes = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  driver: driverPropType,
  onUpdate: PropTypes.func,
};
