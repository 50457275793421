import React, { useState } from 'react';
import { useAsync } from 'react-async';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import HistoryExportButton from '../components/HistoryExportButton';
import HistoryTable, { historyRow } from '../components/HistoryTable';
import { listCurrentTours, listAllTours } from '../api/tourAdapter';
import { useError } from '../components/ErrorContext';

const descendingByDateTime = (parcel1, parcel2) => moment(parcel2.date) - moment(parcel1.date);

export default function StatisticsPage() {
  const { showGenericError } = useError();
  const [backlog, setBacklog] = useState([]);
  const [tourParcels, setTourParcels] = useState([]);

  useAsync(listCurrentTours, {
    onResolve: (data) => {
      const parcels = data.backlogList.map((parcel) => historyRow(null, parcel));
      parcels.sort(descendingByDateTime);
      setBacklog(parcels);
    },
    onReject: showGenericError,
  });
  useAsync(listAllTours, {
    onResolve: (data) => {
      const parcels = data.flatMap((tour) => (
        tour.parcelsList.map((parcel) => historyRow(tour, parcel))
      ));
      parcels.sort(descendingByDateTime);
      setTourParcels(parcels);
    },
    onReject: showGenericError,
  });

  return (
    <>
      <section className="section--padding-top">
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="h3" className="no-margin">Statistik</Typography>
          <HistoryExportButton parcels={backlog.concat(tourParcels)} />
        </Stack>
        <br />
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="h4" className="no-margin">Sendungsarchiv</Typography>
        </Stack>
      </section>
      <section className="section--padding-top">
        <HistoryTable parcels={backlog.concat(tourParcels)} />
      </section>
    </>
  );
}
