import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async';
import { useSideDrawer } from './SideDrawer';
import SaveAbortButtons from './SaveAbortButtons';
import { useError } from './ErrorContext';
import { updateTour } from '../api/tourAdapter';
import { listDrivers } from '../api/driverAdapter';
import { vehiclePropType } from './VehicleForm';
import { driverPropType } from './DriverForm';
import { listVehicles } from '../api/vehicleAdapter';

const UNDEFINED = '__UNDEFINED__';

export function NameIdListDropdown({
  asyncListFunc, label, value, onChange, disabled,
}) {
  const { showGenericError } = useError();
  const [data, setData] = useState(undefined);
  useAsync(asyncListFunc, {
    onResolve: (newData) => setData(newData),
    onReject: showGenericError,
  });

  const onInternalChange = (e) => {
    const newValue = e.target.value;
    const convertedValue = (newValue === UNDEFINED) ? undefined : newValue;
    onChange(convertedValue);
  };

  return (
    <TextField
      label={label}
      value={(value === undefined ? UNDEFINED : value)}
      onChange={onInternalChange}
      margin="normal"
      disabled={disabled || (data === undefined)}
      select
    >
      <MenuItem value={UNDEFINED} disabled><i>Nicht definiert</i></MenuItem>
      {
        (data && data.map(({ id, name }) => (
          <MenuItem key={id} value={id}>{name}</MenuItem>
        )))
      }
    </TextField>
  );
}

NameIdListDropdown.defaultProps = {
  disabled: false,
};

NameIdListDropdown.propTypes = {
  asyncListFunc: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default function TourForm({ tour, onUpdate, editable }) {
  const { closeSideDrawer } = useSideDrawer();
  const { showGenericError } = useError();
  const [currentTour, setCurrentTour] = useState({
    id: tour.id,
    driverId: tour.driver?.id,
    vehicleId: tour.vehicle?.id,
  });

  const setDriverId = (driverId) => setCurrentTour({ ...currentTour, driverId });
  const setVehicleId = (vehicleId) => setCurrentTour({ ...currentTour, vehicleId });

  const onSave = async () => {
    try {
      const { id, driverId, vehicleId } = currentTour;
      const updatedDrivers = await updateTour(id, driverId, vehicleId);
      onUpdate(updatedDrivers);
      closeSideDrawer();
    } catch {
      showGenericError();
    }
  };

  return (
    <>
      <h4>Tour</h4>
      <NameIdListDropdown
        label="Fahrer"
        asyncListFunc={listDrivers}
        value={currentTour.driverId}
        onChange={(id) => setDriverId(id)}
        disabled={!editable}
      />
      <NameIdListDropdown
        label="Fahrzeug"
        asyncListFunc={listVehicles}
        value={currentTour.vehicleId}
        onChange={(id) => setVehicleId(id)}
        disabled={!editable}
      />
      <SaveAbortButtons onSave={onSave} onAbort={closeSideDrawer} disabled={!editable} />
    </>
  );
}

export const tourPropType = PropTypes.shape({
  id: PropTypes.string,
  driver: driverPropType,
  vehicle: vehiclePropType,
  status: PropTypes.string,
});

TourForm.defaultProps = {
  tour: undefined,
  onUpdate: () => {},
  editable: true,
};

TourForm.propTypes = {
  tour: tourPropType,
  onUpdate: PropTypes.func,
  editable: PropTypes.bool,
};
