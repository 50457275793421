/**
 * Resolves when a stream is finished successfully, otherwise fails
 * @param stream
 * @returns {Promise<unknown>}
 */
export const waitForSuccessOrFail = (stream) => new Promise((resolve, reject) => {
  stream.on('status', (response) => {
    if (response.code === 0) {
      // Success
      resolve();
    } else {
      // Error
      reject(response);
    }
  });
});

/**
 * Converts gRPC stream of data to an array
 * @param stream
 * @returns {Promise<*[]>}
 */
export async function streamToArray(stream) {
  const results = [];

  stream.on('data', (response) => {
    results.push(response);
  });

  await waitForSuccessOrFail(stream);

  return results;
}

/**
 * Calls ".toObject()" function for every element of the given array
 * @param array
 * @returns {*[]}
 */
export function arrayToObjects(array) {
  return array.map((item) => item.toObject());
}

/**
 * Loops through async iterator und returns array of results
 * @param asyncIterator
 * @returns {Promise<*[]>}
 */
export async function iteratorToArray(asyncIterator) {
  const array = [];
  // eslint-disable-next-line no-restricted-syntax
  for await (const i of asyncIterator) {
    array.push(i);
  }
  return array;
}

const AUTH_INFO = 'auth_info';
const TENANT_KEY = 'tenant';

const parseAuthInfo = () => {
  const infoString = window.localStorage.getItem(AUTH_INFO);
  return infoString ? JSON.parse(infoString) : {};
};

export const provideCurrentToken = () => {
  const info = parseAuthInfo();
  return info.apitoken;
};

export const provideCurrentUser = () => {
  const info = parseAuthInfo();
  return info.operator || info.maintainer;
};

export const storeAuthInfo = (info) => {
  window.localStorage.setItem(AUTH_INFO, JSON.stringify(info));
};

export const removeAuthInfo = () => {
  window.localStorage.removeItem(AUTH_INFO);
};

export const storeTenant = (tenant) => {
  window.localStorage.setItem(TENANT_KEY, tenant);
};

export const provideTenant = () => window.localStorage.getItem(TENANT_KEY);

export const promisify = (fn, thisArg) => (...args) => new Promise((resolve, reject) => {
  try {
    fn.call(thisArg, ...args, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  } catch (error) {
    reject(error);
  }
});

/**
 * @param requests array of objects to be yielded sequentially
 * @returns {AsyncGenerator<*, void, *>}
 */
export async function* asGenerator(requests) {
  // eslint-disable-next-line no-restricted-syntax -- yield can't be in .forEach callback
  for (const request of requests) {
    yield request;
  }
}
