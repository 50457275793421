import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import ActivityStatus from './ActivityStatus';
import { vehiclePropType } from './VehicleForm';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'capacity',
    headerName: 'Kapazität',
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: [
      { value: true, label: 'Aktiv' },
      { value: false, label: 'Inaktiv' },
    ],
    renderCell: ({ value }) => (
      <ActivityStatus active={value} />
    ),
    flex: 1,
  },
  {
    field: 'notes',
    headerName: 'Notizen',
    flex: 1,
  },
];

export default function VehicleTable({ vehicles, onRowClick }) {
  return (
    <DataGrid
      style={{ height: '68vh', minHeight: 400 }}
      rows={vehicles}
      columns={columns}
      onRowClick={(e) => onRowClick(e.row)}
    />
  );
}

VehicleTable.propTypes = {
  vehicles: PropTypes.arrayOf(vehiclePropType).isRequired,
  onRowClick: PropTypes.func.isRequired,
};
