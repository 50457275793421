import { Empty } from '../proto/models/general_pb';
import {
  arrayToObjects, iteratorToArray,
} from './helpers';
import { CreateVehicleRequest, DeleteVehicleRequest, UpdateVehicleRequest } from '../proto/operator/vehicles_pb';
import { VehicleService } from '../proto/operator/vehicles_pb_service';
import { createServiceClient } from './createServiceClient';

const client = createServiceClient(VehicleService);

const mapStatusToProto = (active) => (active ? 0 : 1);

const mapFromProto = (vehicle) => ({
  ...vehicle,
  status: !vehicle.status,
  capacity: vehicle.carrier.parcelcapacity,
});

/**
 * Fetch list of vehicles
 *
 * @returns {Promise<*[]>}
 */
export const listVehicles = async () => {
  const stream = client.listVehicles(new Empty());
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapFromProto);
};

/**
 * Update a vehicle
 *
 * @param vehicle object describing the new attribute values of the designated vehicle
 * @returns {Promise<*[]>}
 */
export async function updateVehicle(vehicle) {
  const updateRequest = new UpdateVehicleRequest();
  updateRequest.setId(vehicle.id);
  updateRequest.setName(vehicle.name);
  updateRequest.setStatus(mapStatusToProto(vehicle.status));
  updateRequest.setCapacity(vehicle.capacity);
  updateRequest.setNotes(vehicle.notes);
  const stream = client.updateVehicle(updateRequest);
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapFromProto);
}

/**
 * Create a new vehicle
 *
 * @param vehicle object describing the attribute values of the new vehicle
 * @returns {Promise<*[]>}
 */
export async function createVehicle(vehicle) {
  const createRequest = new CreateVehicleRequest();
  createRequest.setName(vehicle.name);
  createRequest.setStatus(mapStatusToProto(vehicle.status));
  createRequest.setCapacity(vehicle.capacity);
  createRequest.setNotes(vehicle.notes);
  const stream = client.createVehicle(createRequest);
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapFromProto);
}

/**
 * Delete a vehicle
 *
 * @param vehicleId of the vehicle to delete
 * @returns {Promise<*[]>}
 */
export async function deleteVehicleById(vehicleId) {
  const deleteRequest = new DeleteVehicleRequest();
  deleteRequest.setId(vehicleId);
  const stream = client.deleteVehicle(deleteRequest);
  const results = await iteratorToArray(stream);
  return arrayToObjects(results).map(mapFromProto);
}
